@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}


#cardContainer{
    width: 95%;
    height: 550px;
    padding: 20px;
    margin: 20px;
    min-width: 350px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 5px 0px;

    #imageContainer{
        width: 100%;
        height: 45%;
        min-height: 240px;
        overflow: hidden;
        // border-radius: 5%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #eventContent{
        height: 55%;
        width: 100%;
        overflow: hidden;

        #title{
            width: 100%;
            text-align: center;
            padding: 3px 0px;
            margin: 5px 0px;
    
            h3{
                font-family: 'Poppins', sans-serif;
            }
        }
    
        #metaDataContainer{
            width: 100%;
            height: fit-content;
            @include alignment;
    
            .metaData:nth-child(2){
                width: 35%;
                border-right: 1px solid grey;
                border-left: 1px solid grey;
            }
    
            .metaData{
                width: 33.33%;
                height: 100%;
                display: flex;
                align-items: center;
    
                i {
                    margin: 0px 10px;
                    // color: #00b069;
                }
    
            }
        }
    
        #jist{
            width: 100%;
            height: max-content;
            padding: 8px;
            overflow: hidden;
            p{
                text-align: justify;
                overflow: hidden;
                text-overflow: ellipsis;
            }
    
        }
    
        #buttonContainer{
            width: 100%;
            height: max-content;
            display: flex;
            align-items: center;
            
            
    
        }
    }

    
    
}

/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 600px){
    #cardContainer{
        min-width: 290px !important; 
        height: 480px;

        #imageContainer{
            min-height: 180px;
            height: 180px;
        }

        #buttonContainer{
        
            button{
                padding: 4px 10px !important;
            }
    
        }
    }

    #title{

        h3{
            font-size: 1rem;
        }
    }

    #jist{
        p{
            font-size: .8rem;
        }

    }
}

@media screen and (max-width: 992px) {
    #cardContainer{

        #buttonContainer{
        
            button{
                padding: 4px 15px !important;
            }
    
        }
    }

    #title{

        h3{
            font-size: 1.2rem;
        }
    }

    #jist{
        p{
            font-size: .8rem;
        }

    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
}