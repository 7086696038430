#CTA:hover{
    border-bottom: 1px solid black !important;
    
    i{
        font-size: 1rem;
        margin: 0px 5px;
    }
}

#CTA{
    width: fit-content;
    i{
        transition: 0.3s;
        font-size: 0rem;
        transition-delay: 0.3s;
        animation: blink 1s linear infinite;
    }
}

@keyframes blink {
    0%{
        color: black;
    }
    100%{
        color: white;
    }
}