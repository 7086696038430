#videosTitle{
    width: 100%;
    height: 30%;
    padding: 1rem;
    display: flex;
    font-size: 3rem;
    text-align: center;
    align-items: end;
    justify-content: center;
}

#videoGridWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    
    #videosGrid{
        width: 100%;
        height: 100%;
        display: grid;
        gap: 1rem 0rem;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
    }
}



/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 600px){
    #videosTitle{
        padding: .5rem;
        font-size: 1.5rem;
    }

    #videoGridWrapper{
        #videosGrid{
            gap: .5rem 0rem;
            grid-template-columns: 1fr;
            // grid-template-rows: 440px;
        }
    }
}

@media screen and (min-width: 600px) {

    #videoGridWrapper{
        #videosGrid{
            gap: .5rem 0rem;
            grid-template-columns: 1fr 1fr;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
    #videoGridWrapper{
        #videosGrid{
            grid-template-columns: 1fr 1fr;
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
    #videoGridWrapper{
        #videosGrid{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}