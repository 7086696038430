#videoBlogContainer{
    margin: 2rem 4rem;
    padding: 2rem;
    border-radius: 10px;
    background-color: rgb(240, 240, 240);

    video{
        width: 100%;
        height: 100%;
    }
}

#videoBlogTitle{
    padding: 1rem;
    font-size: 3rem;
}

#videoBlogDesc{
    padding: 1rem;
}

#metaData{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 1.5rem;
}

.metaItem{
    display: flex;
    justify-content: center;
    align-items: center;

    i{
        margin: 0rem 1rem;
    }
}

/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 992px) {
    #videoBlogContainer{
        margin: 1rem;
        padding: .5rem;
    }

    #metaData{
        flex-wrap: wrap;
        padding: .5rem;
        font-size: 1rem;
    }

    .metaItem{    
        i{
            margin: 0rem;
            margin-right: .5rem;   
        }
    }

    #videoBlogTitle{
        padding: .5rem;
        font-size: 1.5rem;
    }

    #videoBlogDesc{
        padding: .5rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
}