@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

#GEWrapper{
    width: 100%;
    margin: 70px 0px;
    @include alignment;
    position: relative;

    #GECurve{
        position: absolute;
        right: 0%;
    }

    #GESquare{
        position: absolute;
        bottom: 440px;
        right: 75px;
        backface-visibility: hidden;
        animation: squareAnimation 9s ease-in-out 2s infinite alternate;
        animation-delay: 1s;
        transition: all 1s ease 3s;
        user-select: none;

        @keyframes squareAnimation{
            0% {
                transform: translateY(0px) translateX(0) rotate(0) scale(1);
                opacity: 0.5;
            }
            
            25% {
                transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
                opacity: 0.8;
            }
            50% {
                transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
                opacity: 0.8;
            }
            75% {
                transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
                opacity: 0.7;
            }
            100% {
                transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
                opacity: 0.9;
            }
        }
    }

    #GETriangle{
        position: absolute;
        bottom: 425px;
        right: 125px;
        backface-visibility: hidden;
        animation: triangleAnimation 9s ease-in-out 2s infinite alternate;
        animation-delay: 1s;
        transition: all 7s ease 2s;
        user-select: none;

        @keyframes triangleAnimation{
            0% {
                transform: translateY(10px) translateX(0) rotate(0) scale(1);
                opacity: 0.9;
            }
            
            20% {
                transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
                opacity: 0.8;
            }
            40% {
                transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
                opacity: 0.8;
            }
            40% {
                transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
                opacity: 1;
            }
            80% {
                transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
                opacity: 0.6;
            }
            100% {
                transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
                opacity: 0.7;
            }
        }

    }

    #getEmpoweredContainer{
        width: 100%;
        height: 75%;
        display: flex;
        align-content: space-between;
        justify-content: center;
        flex-wrap: wrap;

        #GECHeadingContainer{
            width: 100%;
            height: 20%;
            @include alignment;
            flex-wrap: wrap;
           
            #GECTitle{
                width: 80%;
                @include alignment;
                text-align: center;
                h1{
                    font-weight: 900;
                    font-size: 2.5rem;
                    font-family: 'Commissioner', sans-serif;
                }
            }
        
            #GECsubTitle{
                width: 50%;
                margin: 25px 0px;
                @include alignment;
                text-align: center;
            }
        }
    
    
        #GECcardsContainer{
            width: 95%;
            // @include alignment;
            display: flex;
            flex-wrap: wrap;
            row-gap: 50px;
            justify-content: center;
            
            .GECCardContainer{
                width: 23%;
                min-width: 300px;
                @include alignment;
                flex-wrap:wrap ;
                // height: 175px;
                
                .GECCiconContainer{
                    width: 100%;
                    height: 50%;
                    max-height: 90px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
    
                .GECCTitleCotnainer{
                    width: 100%;
                    height: 20%;
                    text-align: center;
    
                    h2{
                        color: rgb(80, 80, 80);
                    }
                }
    
                .GECCSubtitleContainer{
                    width: 80%;
                    height: 30%;
                    text-align: center;
                }
    
            }        
    
        }
    
    }

}


@media screen and (max-width: 500px) {
    #GECTitle{
        h1{
            font-size: 2rem !important;
            text-align: center !important;
        }
    }   
    #GETriangle{
        display: none;
    }

    #GESquare{
        display: none;
    }
    
    #GECurve{
        width: 20% !important;
    }

}
