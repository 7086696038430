#bannerContainerWrapper{
    width: 100%;
    height: 60%;
    display: flex;
    align-items: end;
}

#bannerContainer{
    width: 100%;
    height: 40vh;
    position: relative;
}

#bannerContainer > img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
}

#bannerTitle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: white;
}


/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 992px) {
    #bannerContainer{
        width: 100%;
        height: 20vh;
        position: relative;
    }

    #bannerTitle{
        font-size: 2rem;
        color: white;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
    #bannerContainer{
        height: 30vh;
    }

    #bannerTitle{
        font-size: 3rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
    #bannerContainer{
        height: 40vh;
    }

    #bannerTitle{
        font-size: 5rem;
    }
}