@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

#videoContainer{
    width: 100%;
    height: 100vh;

    video{
        object-fit: cover;
    }
}

#statisticsWrapper{
    width: 100%;
    margin: 50px 0px;
    @include alignment;
    flex-wrap: wrap;    

    #heading{
        width: 85%;
        margin: 20px 0px;
        h1{
            font-weight: 900;
            font-size: 3rem;
            font-family: 'Commissioner', sans-serif;
        }
    }

    #statisticsCardContainer{
        width: 85%;
        height: 65%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        position: relative;
        span{
            right: -5%;
            bottom: -50%;
            position: absolute;
            img{
                animation: rotateCircle 20s infinite alternate-reverse;
            }

            @keyframes rotateCircle{
                0%{
                    transform: rotate(0deg);
                } 100% {
                    transform: rotate(360deg);
                }
            }
        }
        
        .card{
            width: 100%;
            height: 100%;
            display: flex;
            padding: 25px;
            flex-wrap: wrap;
            align-content: center;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

            .scount, .stext{
                width: 100%;
                height: 50%;
            }
            .stext{
                padding: 5px 0px;
            }

            .scount{
                h1{
                    font-size: 4rem;
                    width: 100%;
                    font-family: 'Commissioner', sans-serif;
                }
            }
            p{
                width: 100%;
                font-size: 1.3rem;
            }

        }
    }

    

}



#HEROcarouselContainer{
    width: 100%;
    height: 88%;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;

    #carouselOverlay{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
    }

    #HEROcarouselWrapper{
        width: 100%;
        height: 100%;

        #slider{
            width: 800%;
            height: 100%;
            display: flex;
            transition: 1.4s linear;

            .imageContainer{
                width: 100%;
                height: 100%;
                position: relative;

                #carouselText{
                    width: 50%;
                    height: 40%;
                    z-index: 1;
                    left: 10%;
                    color: white;
                    bottom: 0%;
                    position: absolute;
                    p{
                        margin: 10px 0px;
                    }

                    #carouselCTA{
                        width: 60px;
                        color: black;


                        #carouselCTAButton{
                            margin: 10px 0px;
                            padding: 10px 25px;
                            width: fit-content;
                            background-color: white;
                            p{
                                margin: 0px;
                            }
                        }
                    }
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

            }
        }

    }    

}



#aboutUsContianerWrapper{
    width: 100%;
    min-height: 100vh;
    margin: 50px 0px;
    @include alignment;

    #aboutUsContainer{
        width: 100%;
        height: 70%;
        @include alignment;

        #AUCleftSection, #AUCrightSection{
            width: 30%;
            height: 100%;
            position: relative;
        }

        #AUCleftSection{
            min-width: 400px;
            height: 80vh;

            #AUCImageFloater{
                width: 120px;
                height: 120px;
                z-index: 1;
                @include alignment;
                position: absolute;
                

                #bigBubble{
                    width: 95%;
                    height: 95%;
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 23px;
                    border-radius: 50%;
                    position: relative;
                    align-content: center;
                    justify-content: center;
                    background-color: var(--primary-color);

                    h5, h1{
                        width: 100%;
                        color: white;
                        @include alignment;
                    }

                }

                #smallBubble{
                    width: 25%;
                    height: 25%;
                    right: 1%;
                    top: 3%;
                    z-index: -1;
                    border-radius: 50%;
                    position: absolute;
                    background-color: #fdbf27;
                }

            }

            #AUCLSImageContainer{
                width: 85%;
                height: 90%;
                left: 15%;
                top: 10%;
                position: relative;
                border-radius: 10px;
                overflow: hidden;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }
        }

        #AUCrightSection{
            width: 55%;
            display: flex;
            align-content: center;
            justify-content: right;
            flex-wrap: wrap;

            #AUmainTitle{
                width: 90%;
                margin: 10px 0px;  
                font-weight: 900;
                font-size: 2.5rem;
            }

            #experienceTitle, #briefIntro, #phoneNumber{
                width: 90%;
                margin: 10px 0px;
                text-align: left;
            }

            #experienceTitle{
                font-size: 1.3rem;
                color: var(--primary-color);
            }

            #CTAButtonContainer{
                width: 90%;
                margin: 10px 0px;

                button{
                    cursor: pointer;
                    color: white;
                    border: none;
                    font-weight: 500;
                    padding: 10px 30px;
                    border-radius: 50px;
                    background-color: var(--primary-color);
                }

            }
        }

    }

}











@media screen and (max-height: 651px){
    #carouselText{
        bottom: 15% !important;
    }
}



/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */

@media screen and (min-width: 600px) {
    
    #videosGrid{
        gap: .5rem 0rem !important;
        grid-template-columns: 1fr 1fr !important;
    }
}


/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {

    #videosGrid{
        grid-template-columns: 1fr 1fr;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
    #videosGrid{
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}



@media screen and (max-width: 950px) {
    #statisticsCardContainer{
        grid-template-columns: repeat(3, 1fr) !important;
    }

    #aboutShresthaClubContainer{
        flex-direction: column !important;

        #ASCcontent, #ASCVideoContainer{
            width: 100% !important;
            margin: 25px 0px;
            video{
                width: 100% !important;
            }
        }

        #videoWrapper{
            height: 250px !important;
        }

        #ASCtext{
            width: 100% !important;
        }
        
    }
}


@media screen and (max-width: 750px) {
    #statisticsCardContainer{
        grid-template-columns: repeat(2, 1fr) !important;
    }

    #AUCleftSection{
        display: none;
    }

    #AUCrightSection{
        width: 90% !important;
    }

    #GECsubTitle{
        width: 85% !important;
    }
}

@media screen and (max-width: 600px){

    .card{
        h1{
            font-size: 2.7rem !important;
        }
        p{
            font-size: 1rem !important;
        }
    }

    #videosGrid{
        gap: .5rem 0rem !important;
        grid-template-columns: 1fr !important;
        // grid-template-rows: 440px !important;
    }

    #TSLeftSection{
        display: none !important;
    }

    #TSRightSection{
        width: 100% !important;
    }
}


@media screen and (max-width: 520px) {
    #heading{
        margin: 10px 0px !important; 

        h1{
            font-size: 2rem !important;
        }
    }
    #carouselText{
        padding: 5px;
        left: 3% !important;
        width: 90% !important;
    }   
}

@media screen and (max-width: 500px) {
    #ASCcontent, #ASCVideoContainer{
        height: 400px !important;
    }
}

@media screen and (max-width: 400px){
    #TCImageSection{
        img{
            width: 80px !important;
            height: 80px !important;
            border-radius: 50%;
        }

    }

    #statisticsCardContainer{
        .card{
            padding: 15px !important;
        }
    }

    .scount{
        h1{
            font-size: 1.8rem !important;
            width: 100%;
            font-family: 'Commissioner', sans-serif;
        }
    }
    .stext{
        p{
            font-size: 0.6rem !important;
        }
    }
}





