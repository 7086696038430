@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}


#navbarContainer{
    width: 100%;
    height: 12%;
    top: 0px;
    position: sticky;
    // position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 9999;

    #logoContainer{
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: left;
        img{
            height: 100%;
            object-fit: contain;
        }

    }
    
    #menuContainer{
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        transition: all 0.5s ease-in-out;

        i{
            display: none;
            color: white;
        }

        .menuItemContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0rem 1rem;

            .menu{
                color: white;
                height: 50%;
                width: 15%;
                font-size: 1rem;
                text-decoration: none;
                @include alignment;
                transition: 0.2s ease-out;
                div p{
                    font-size: 1rem;                    
                }
            }

            .menu:hover{
                background-color: white;
                color: black;
            }

            .menu:active{
                background-color: white;
                color: black;
            }
        }
    }

}


@media screen and (max-width: 600px){
    #navbarContainer{
        justify-content: space-between;
        padding: 0rem 1rem;

        // #logoContainer{
        //     width: 6%;
        // }

        #menuContainer{
            flex-direction: column;
            align-items: flex-end;
            position: relative;
            
            i{
                display: block !important;
                font-size: 1.5rem;
                cursor: pointer;
            }
            
            .menuItemContainer{
                display: none !important;
                
                &.active{
                    position: absolute;
                    display: flex !important;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    
                    padding: .5rem;
                    height: 750%;
                    width: 150%;
                    top: 100%;
                    right: -9%;
                    background-color: rgba(0, 0, 0, 0.8);
                    
                    .menu{
                        width: 100%;
                        color: black;
                        padding: 10px 0px;
                        height: fit-content;
                        margin-bottom: .5rem;
                        background-color: white;
                        border-bottom: 1px solid white;
                        
                        &:nth-last-child(1){
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px){
    #navbarContainer{
        justify-content: space-between;
        padding: 0rem 1rem;

        #menuContainer{
            flex-direction: column;
            align-items: flex-end;
            position: relative;
            
            i{
                font-size: 1.5rem;
                display: block !important;
                cursor: pointer;
            }
            
            .menuItemContainer{
                display: none !important;
                
                &.active{
                    position: absolute;
                    display: flex !important;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    
                    padding: .5rem;
                    height: 750%;
                    width: 100%;
                    top: 100%;
                    right: -9%;
                    background-color: rgba(0, 0, 0, 0.8);
                    
                    .menu{
                        width: 100%;
                        margin-bottom: .5rem;
                        border-bottom: 1px solid white;
                        font-size: 1.8rem;
                        
                        &:nth-last-child(1){
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
    #navbarContainer{

        #menuContainer{
            
            i{
                display: none !important;
            }
            
            .menuItemContainer{
                display: flex !important;
                
                .menu{
                    width: fit-content;
                    font-size: 1rem;
                    padding: 1rem;
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (max-width: 1200px) {
    #navbarContainer{

        #logoContainer{
            width: 20% !important;

            img{
                width: 100%;
            }
        }

        #menuContainer{
            .menuItemContainer{
                .menu{
                    width: max-content;
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #navbarContainer{
        #logoContainer{
            width: 50% !important;

            img{
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #logoContainer{
        // width: 40% !important;
    }
}
