@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.essentialContainer {
    
    padding-top: 10rem;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    

    .essentialTitle {
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 20px;
        
        #title {
            // font-size: 3em;
            font-size: clamp(1rem, 2vw + 0.75rem, 3rem);
        }
        
        #subTitle {
            // font-size: 2em;
            font-size: clamp(.5rem, 2vw + 0.75rem, 2rem);
        }
    }

    .essentialContent {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: center;
    }
}