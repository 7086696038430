@mixin alignment{
    display: flex;
    align-items: center;
    justify-content: center;
}

#Wrapper{
    width: 100%;
    @include alignment;


    #container{
        width: 100%;
        
        
        #banner{
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;
            background-position-x: right;

            #bannerText{
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                color: white;
                padding: 40px;
                align-content: end;
                background: linear-gradient(180deg, rgba(0,0,0,0.05504199971004031) 0%, rgba(0,0,0,1) 90%);

                #eventTitle, #eventLocationNDate, #eventSubtitle{
                    width: 100%;
                }

                #eventTitle{
                    h1{
                        font-size: 4rem;
                        font-weight: 900;
                        letter-spacing: 3px;
                        font-family: 'Commissioner', sans-serif;
                    }
                }

                #eventLocationNDate{
                    display: flex;
                    #location, #date{
                        display: flex;
                        align-items: center;
                        margin: 0px 10px;
                        margin-left: 0px;
                        i{
                            margin: 0px 10px;
                            margin-left: 0px;
                        }

                    }

                }
            }
        }
        
        

        #contentContainer{
            width: 100%;
            height: fit-content;
            display: flex;

            #leftSection{
                width: 60%;
                height: fit-content;

                display: flex;
                justify-content: center;
                
                #leftSectionWrapper{
                    width: 85%;
                    height: fit-content;

                    #eventDetailText{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        height: fit-content;
                        align-content: space-evenly;
    
                        #eventHeading{
                            width: 100%;
                            h1{
                                width: fit-content;
                                font-size: 3rem;
                                font-weight: 900;
                                margin: 25px 0px;
                                font-family: 'Commissioner', sans-serif;
                            }
                        }
                        #ASCtext{
                            width: 100%;
                            height: 75%;
                            text-align: justify
                        }
                    }
    
                    #videoSection{
                        width: 100%;
                        height: 300px;
                        @include alignment;
                        margin: 50px 0px;
                    }

                    #extraSection{
                        width: 100%;
                        height: 70%;
                        margin-bottom: 50px;

                        #ESTitle{
                            height: 15%;
                            margin: 25px 0px;
                        }

                        #ESCards{
                            gap: 40px;
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;

                            #ESCard{
                                width: 27%;
                                height: 180px;
                                min-width: 180px;
                                @include alignment;
                                flex-wrap: wrap;
                                box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 5px 0px;
                                
                                #icon{
                                    width: 100%;
                                    height: 60%;
                                    @include alignment;

                                    img{
                                        width: 70%;
                                        object-fit: contain;
                                    }
                                    i{
                                        font-size: 5rem;
                                    }
                                }

                                #cardText{
                                    width: 80%;
                                    text-align: center;
                                }
                            }
                        }
                    }

                }

            }

            #rightSection{
                width: 40%;
                display: flex;
                justify-content: center;
                
                
                #rightSectionWrapper{
                    width: 80%;
                    
                    #registerContainer{
                        width: 100%;
                        height: 500px;
                        display: flex;
                        padding: 25px;
                        flex-wrap: wrap;
                        transition: 0.4s;
                        border-radius: 7px;
                        transform: translateY(-100px);
                        align-content: space-between;
                        box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 5px 0px;
                        // background-color: white;

                        #Rtitle{
                            width: 100%;
                            display: flex;
                            cursor: pointer;
                            align-items: center;
                            padding: 10px 0px;
                            border-radius: 5px;
                            justify-content: center;
                            background-color: white;
                            padding: relative;
                            
                            i{
                                z-index: 1;
                                color: black;
                                font-size: 1.6rem;
                                margin: 0px 10px;
                                position: relative;
                                background-color: white !important;

                                // #pulse{
                                //     width: 150%;
                                //     height: 150%;
                                //     left: 50%;
                                //     top: 50%;
                                //     z-index: -1;
                                //     border-radius: 50%;
                                //     position: absolute;
                                //     background-color: black;
                                //     transform: translate(-50%, -50%);
                                //     // animation: upNdown 0.7s ease-in infinite reverse;
                                // }
                                @keyframes upNdown {
                                    from{
                                        width: 0%;
                                        height: 0%;
                                        opacity: 1;
                                    } to {
                                        width: 200%;
                                        height: 200%;
                                        opacity: 0;
                                    }  
                                }
                            }



                            h1{
                                font-size: 2rem;
                                font-weight: 900;
                                text-align: center;
                                font-family: 'Commissioner', sans-serif;
                            }
                        }

                        #inputFields{
                            width: 100%;
                            height: 80%;
                            @include alignment;
                            flex-wrap: wrap;
                            
                            

                            .inpfield{
                                width: 90%;
                                height: 12%;
                                position: relative;
                                
                                input{
                                    width: 100%;
                                    padding: 10px;
                                    height: 100%;
                                    font-size: 1.1rem;
                                    outline: none;
                                    border: none;
                                    border-bottom: 1px solid gray;
                                }
                            }

                            // .inpfield::before{
                            //     content: " ";
                            //     left: 0px;
                            //     width: 10%;
                            //     height: 100%;
                            //     z-index: 99;
                            //     position: absolute;
                            //     background-color: red;
                            //     border-bottom: 2px solid black;
                            // }
                            
                            

                            #submitButton{
                                width: 100%;
                                height: 12%;
                                @include alignment;
                            }
                        }

                        
                    }

                    #speakersSection{
                        width: 100%;
                        margin: 40px 0px;

                        #speakersTitle{
                            margin: 30px 0px;
                            
                            h1{
                                font-size: 3rem;
                                font-weight: 900;
                                font-family: 'Commissioner', sans-serif;
                            }
                        }

                        .speaker{
                            width: 100%;
                            height: 110px;
                            margin: 20px 0px;
                            display: flex;
                            justify-content: space-evenly;
                            box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 5px 0px;
                            
                            
                            .speakerIconContainer{
                                width: 100px;
                                height: 100px;
                                padding: 3px;
                                overflow: hidden;
                                border-radius: 50%;
                                

                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .speakerDetailsContainer{
                                width: 70%;
                                height: 100%;
                                padding: 6px 15px;

                                h1{
                                    font-size:  1.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 930px){
    #contentContainer{
        flex-direction: column;
        #leftSection, #rightSection{
            width: 100% !important;
        }

        #rightSection{
            #registerContainer{
                transform: translateY(0px) !important;
                margin: 40px 0px;
            }
        }
    }
}

@media screen and (max-width: 500px){
    #videoSection{
        margin: 10px 0px !important;
    }
    #eventLocationNDate{
        flex-direction: column;
    }
    #eventSubttitle{
        margin: 10px 0px;
    }
    #Rtitle{
        i{
            display: none !important;
        }
    }
}