@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventsContainer {
    // width: 100%;
    padding-top: 10rem;
    padding-right: 10vw;
    padding-left: 10vw;
    flex-wrap: wrap;
    @include alignment();

    .eventsTitle {
        // position: relative;
        // width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        text-align: center;

        h2 {
            font-weight: 700;
            font-size: clamp(.5rem, 2vw + 0.75rem, 3rem);
            // line-height: 50px;
        }

        p {
            line-height: 30px;
            font-size: clamp(.5rem, 2vw + 0.75rem, 1rem);
            color: #626a77;
            font-family: "Poppins", sans-serif;
        }
    }

}

.eventCard {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0px;
    align-items: center;
    padding: 0.4rem 2.5rem;
    box-shadow: 0px 30px 70px 0px rgba(2, 25, 55, 0.08);
    border-radius: 10px;
    padding-top: 2rem;
    padding-bottom: 2rem;

    #leftSection{
        width: 20%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 50% 0 50% 50%;
        }
    }

    .eventContent {
        width: 80%;
        // max-width: 50vw;
        padding-left: 2rem;
    }

    // .imgFluid {
    //     border-radius: 50% 0 50% 50%;
    //     padding-right: .5rem;
    //     height: auto;
    //     width: 16em;
    //     vertical-align: middle;
    //     border-style: none;
    // }

    p {
        line-height: 30px;
        font-size: clamp(.5rem, 2vw + 0.75rem, 1rem);
        margin-bottom: 15px;
        color: #626a77;
        font-family: "Poppins", sans-serif;
    }

    h2 {
        font-size: clamp(.5rem, 2vw + 0.75rem, 2rem);
    }

    h5 {
        font-size: clamp(.5rem, 2vw + 0.75rem, .8rem);
    }

    
}

@media screen and (max-width: 1230px){
    #leftSection{
        display: none;
    }

    .eventContent{
        width: 100% !important;
    }
}