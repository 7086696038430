@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

#aboutShresthaClubWrapper{
    width: 100%;
    margin: 50px 0px;
    @include alignment;    

    #aboutShresthaClubContainer{
        width: 85%;
        display: flex;
        flex-wrap: wrap;

        #ASCHeading{
            width: 100%;
            margin: 25px 0px;
            h1{
                font-weight: 900;
                position: relative;
                width: fit-content;
                margin: 0px;
                font-size: 3rem;
                font-family: 'Commissioner', sans-serif;
            }
            span{
                font-weight: 700;
            }
        }
        .sec{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0px;

            #ASCcontent{
                width: 60%;
                display: flex;
                flex-wrap: wrap;
                align-content: space-evenly;

                #ASCtext{
                    width: 90%;
                    // height: 75%;
                    font-size: 1rem;
                    text-align: center;
                    text-align: justify
                }
            }
    
            #ASCVideoContainer{
                width: 40%;
                @include alignment;

                img{
                    width: 100%;
                    object-fit: contain;
                    max-height: 350px;
                }
                video{
                    width: 100%;
                    height: 470px;
                }
            }
        }

        .sec:nth-child(3) #ASCcontent{
            justify-content: flex-end;
        }
    }
}


@media screen and (max-width: 800px){
    #aboutShresthaClubContainer{
        flex-direction: column;

        .sec:nth-child(3) {
            flex-direction: column-reverse;            
        }

        #ASCcontent, #ASCVideoContainer{
            width: 100% !important;
            padding: 15px 0px;
        }

        #ASCVideoContainer{
            video{
                margin: 30px 0px;
                height: fit-content !important;
            }
        }

        #ASCHeading{
            width: 100%;
            h1{
                margin: 25px 0px;
            }
        }

        #ASCtext{
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 700px){
    #ASCHeading{
        h1{
            font-size: 2rem !important;
        }
        span{
            p{
                font-size: 1rem !important;
            }
        }
    }
}

@media screen and (max-width: 600px){
    #ASCtext{
        font-size: 0.8rem !important; 
    }
}

@media screen and (max-width: 500px){
    #ASCHeading{
        margin: 0px !important;
        h1{
            font-size: 1.5rem !important;
        }
        span{
            p{
                font-size: 0.7rem !important;
            }
        }
    }
}