@mixin alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

#eventCardContainer{
    width: 100%;
    color: white;
    flex-wrap: wrap;
    column-gap: 30px;
    @include alignment;    
    padding: 70px 0px;
    // background-color: #f7f7f7;
    background-color: #001846;

    #heading{
        width: 100%;
        display: flex;
        margin: 0px 0px 20px 0px;
        text-align: center;
        align-items: center;
        justify-content: center;
        
        // background-color: rgba(253, 191, 39, 0.5);

        h1{
            font-size: 3rem;
            font-weight: 900;
            position: relative;
            font-family: 'Commissioner', sans-serif;

            #underline{
                width: 100%;
                left: 5%;
                bottom: -20%;
                position: absolute;
                border: 4px solid var(--primary-color);
            }
        }

    }

    #videoGridWrapper{
        width: 85%;
        // display: flex;
        justify-content: center;
        
        #videosGrid{
            width: 90%;
            height: 100%;
            display: grid;
            gap: 1rem 0rem;
            grid-template-columns: 1fr 1fr 1fr;
            place-items: center;
        }
    }

}

.eventCard {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0px;
    padding-top: 2rem;
    align-items: center;
    border-radius: 10px;
    padding-bottom: 2rem;
    padding: 0.4rem 2.5rem;
    border: 1px solid white;
    box-shadow: 0px 30px 70px 0px rgba(2, 25, 55, 0.08);

    #leftSection{
        width: 20%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            width: 200px;
            height: 200px;
            object-fit: cover;
            // border-radius: 50% 0 50% 50%;
        }
    }

    .eventContent {
        width: 80%;
        // max-width: 50vw;
        padding-left: 2rem;
    }

    p {
        line-height: 30px;
        font-size: clamp(.5rem, 2vw + 0.75rem, 1rem);
        margin-bottom: 15px;
        // color: #626a77;
        font-family: "Poppins", sans-serif;
    }

    h2 {
        font-size: clamp(.5rem, 2vw + 0.75rem, 2rem);
    }

    h5 {
        font-size: clamp(.5rem, 2vw + 0.75rem, .8rem);
    }

    
}


@media screen and (max-width: 1230px){
    #leftSection{
        display: none;
    }

    .eventContent{
        width: 100% !important;
    }
}

@media screen and (max-width: 750px) {
    #eventCardContainer{
        padding: 70px 0px;
    }
}

@media screen and (max-width: 500px){
    #heading{
        h1{
            font-size: 2rem !important;
        }
    }
}