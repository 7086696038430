body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#navbarContainer {
  width: 100%;
  height: 12%;
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
}
#navbarContainer #logoContainer {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: left;
}
#navbarContainer #logoContainer img {
  height: 100%;
  object-fit: contain;
}
#navbarContainer #menuContainer {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s ease-in-out;
}
#navbarContainer #menuContainer i {
  display: none;
  color: white;
}
#navbarContainer #menuContainer .menuItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0rem 1rem;
}
#navbarContainer #menuContainer .menuItemContainer .menu {
  color: white;
  height: 50%;
  width: 15%;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out;
}
#navbarContainer #menuContainer .menuItemContainer .menu div p {
  font-size: 1rem;
}
#navbarContainer #menuContainer .menuItemContainer .menu:hover {
  background-color: white;
  color: black;
}
#navbarContainer #menuContainer .menuItemContainer .menu:active {
  background-color: white;
  color: black;
}

@media screen and (max-width: 600px) {
  #navbarContainer {
    justify-content: space-between;
    padding: 0rem 1rem;
  }
  #navbarContainer #menuContainer {
    flex-direction: column;
    align-items: flex-end;
    position: relative;
  }
  #navbarContainer #menuContainer i {
    display: block !important;
    font-size: 1.5rem;
    cursor: pointer;
  }
  #navbarContainer #menuContainer .menuItemContainer {
    display: none !important;
  }
  #navbarContainer #menuContainer .menuItemContainer.active {
    position: absolute;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem;
    height: 750%;
    width: 150%;
    top: 100%;
    right: -9%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  #navbarContainer #menuContainer .menuItemContainer.active .menu {
    width: 100%;
    color: black;
    padding: 10px 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 0.5rem;
    background-color: white;
    border-bottom: 1px solid white;
  }
  #navbarContainer #menuContainer .menuItemContainer.active .menu:nth-last-child(1) {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 600px) {
  #navbarContainer {
    justify-content: space-between;
    padding: 0rem 1rem;
  }
  #navbarContainer #menuContainer {
    flex-direction: column;
    align-items: flex-end;
    position: relative;
  }
  #navbarContainer #menuContainer i {
    font-size: 1.5rem;
    display: block !important;
    cursor: pointer;
  }
  #navbarContainer #menuContainer .menuItemContainer {
    display: none !important;
  }
  #navbarContainer #menuContainer .menuItemContainer.active {
    position: absolute;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem;
    height: 750%;
    width: 100%;
    top: 100%;
    right: -9%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  #navbarContainer #menuContainer .menuItemContainer.active .menu {
    width: 100%;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid white;
    font-size: 1.8rem;
  }
  #navbarContainer #menuContainer .menuItemContainer.active .menu:nth-last-child(1) {
    margin-bottom: 0;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #navbarContainer #menuContainer i {
    display: none !important;
  }
  #navbarContainer #menuContainer .menuItemContainer {
    display: flex !important;
  }
  #navbarContainer #menuContainer .menuItemContainer .menu {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1rem;
    padding: 1rem;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (max-width: 1200px) {
  #navbarContainer #logoContainer {
    width: 20% !important;
  }
  #navbarContainer #logoContainer img {
    width: 100%;
  }
  #navbarContainer #menuContainer .menuItemContainer .menu {
    width: -webkit-max-content;
    width: max-content;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 800px) {
  #navbarContainer #logoContainer {
    width: 50% !important;
  }
  #navbarContainer #logoContainer img {
    width: 100% !important;
  }
}
#Videos_videosTitle__3ZpS3 {
  width: 100%;
  height: 30%;
  padding: 1rem;
  display: flex;
  font-size: 3rem;
  text-align: center;
  align-items: end;
  justify-content: center;
}

#Videos_videoGridWrapper__1z1pX {
  width: 100%;
  display: flex;
  justify-content: center;
}
#Videos_videoGridWrapper__1z1pX #Videos_videosGrid__3peLH {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 1rem 0rem;
  gap: 1rem 0rem;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 600px) {
  #Videos_videosTitle__3ZpS3 {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
  #Videos_videoGridWrapper__1z1pX #Videos_videosGrid__3peLH {
    grid-gap: 0.5rem 0rem;
    gap: 0.5rem 0rem;
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 600px) {
  #Videos_videoGridWrapper__1z1pX #Videos_videosGrid__3peLH {
    grid-gap: 0.5rem 0rem;
    gap: 0.5rem 0rem;
    grid-template-columns: 1fr 1fr;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #Videos_videoGridWrapper__1z1pX #Videos_videosGrid__3peLH {
    grid-template-columns: 1fr 1fr;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  #Videos_videoGridWrapper__1z1pX #Videos_videosGrid__3peLH {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
#Card_cardContainer__3zBjj {
  width: 95%;
  height: 550px;
  padding: 20px;
  margin: 20px;
  min-width: 350px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}
#Card_cardContainer__3zBjj #Card_imageContainer__1VOHj {
  width: 100%;
  height: 45%;
  min-height: 240px;
  overflow: hidden;
}
#Card_cardContainer__3zBjj #Card_imageContainer__1VOHj img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J {
  height: 55%;
  width: 100%;
  overflow: hidden;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_title__kDaXB {
  width: 100%;
  text-align: center;
  padding: 3px 0px;
  margin: 5px 0px;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_title__kDaXB h3 {
  font-family: "Poppins", sans-serif;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_metaDataContainer__1nS-s {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_metaDataContainer__1nS-s .Card_metaData__14Ez2:nth-child(2) {
  width: 35%;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_metaDataContainer__1nS-s .Card_metaData__14Ez2 {
  width: 33.33%;
  height: 100%;
  display: flex;
  align-items: center;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_metaDataContainer__1nS-s .Card_metaData__14Ez2 i {
  margin: 0px 10px;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_jist__3cQk- {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8px;
  overflow: hidden;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_jist__3cQk- p {
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
}
#Card_cardContainer__3zBjj #Card_eventContent__1Zy2J #Card_buttonContainer__AlchS {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  align-items: center;
}

/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 600px) {
  #Card_cardContainer__3zBjj {
    min-width: 290px !important;
    height: 480px;
  }
  #Card_cardContainer__3zBjj #Card_imageContainer__1VOHj {
    min-height: 180px;
    height: 180px;
  }
  #Card_cardContainer__3zBjj #Card_buttonContainer__AlchS button {
    padding: 4px 10px !important;
  }
  #Card_title__kDaXB h3 {
    font-size: 1rem;
  }
  #Card_jist__3cQk- p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 992px) {
  #Card_cardContainer__3zBjj #Card_buttonContainer__AlchS button {
    padding: 4px 15px !important;
  }
  #Card_title__kDaXB h3 {
    font-size: 1.2rem;
  }
  #Card_jist__3cQk- p {
    font-size: 0.8rem;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
#Button_CTA__3q_c1:hover {
  border-bottom: 1px solid black !important;
}
#Button_CTA__3q_c1:hover i {
  font-size: 1rem;
  margin: 0px 5px;
}

#Button_CTA__3q_c1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
#Button_CTA__3q_c1 i {
  transition: 0.3s;
  font-size: 0rem;
  transition-delay: 0.3s;
  -webkit-animation: Button_blink__31X_l 1s linear infinite;
          animation: Button_blink__31X_l 1s linear infinite;
}

@-webkit-keyframes Button_blink__31X_l {
  0% {
    color: black;
  }
  100% {
    color: white;
  }
}

@keyframes Button_blink__31X_l {
  0% {
    color: black;
  }
  100% {
    color: white;
  }
}
#VideoBlog_videoBlogContainer__3Z_Sj {
  margin: 2rem 4rem;
  padding: 2rem;
  border-radius: 10px;
  background-color: rgb(240, 240, 240);
}
#VideoBlog_videoBlogContainer__3Z_Sj video {
  width: 100%;
  height: 100%;
}

#VideoBlog_videoBlogTitle__6dmnW {
  padding: 1rem;
  font-size: 3rem;
}

#VideoBlog_videoBlogDesc__1r31v {
  padding: 1rem;
}

#VideoBlog_metaData__3p93R {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
}

.VideoBlog_metaItem__1ZTue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoBlog_metaItem__1ZTue i {
  margin: 0rem 1rem;
}

/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 992px) {
  #VideoBlog_videoBlogContainer__3Z_Sj {
    margin: 1rem;
    padding: 0.5rem;
  }
  #VideoBlog_metaData__3p93R {
    flex-wrap: wrap;
    padding: 0.5rem;
    font-size: 1rem;
  }
  .VideoBlog_metaItem__1ZTue i {
    margin: 0rem;
    margin-right: 0.5rem;
  }
  #VideoBlog_videoBlogTitle__6dmnW {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
  #VideoBlog_videoBlogDesc__1r31v {
    padding: 0.5rem;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
#Banner_bannerContainerWrapper__pJucr {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: end;
}

#Banner_bannerContainer__2NIbo {
  width: 100%;
  height: 40vh;
  position: relative;
}

#Banner_bannerContainer__2NIbo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

#Banner_bannerTitle__2vpFe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 5rem;
  color: white;
}

/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 992px) {
  #Banner_bannerContainer__2NIbo {
    width: 100%;
    height: 20vh;
    position: relative;
  }
  #Banner_bannerTitle__2vpFe {
    font-size: 2rem;
    color: white;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #Banner_bannerContainer__2NIbo {
    height: 30vh;
  }
  #Banner_bannerTitle__2vpFe {
    font-size: 3rem;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  #Banner_bannerContainer__2NIbo {
    height: 40vh;
  }
  #Banner_bannerTitle__2vpFe {
    font-size: 5rem;
  }
}
#Comment_commentContainer__2icTw {
  width: 100%;
  height: 80%;
  margin: 15px 0px;
}

#Comment_comment__36Qcp {
  width: 100%;
  display: flex;
  align-items: center;
}
#Comment_comment__36Qcp #Comment_commentLeftSection__1gR6M {
  width: 50px;
  height: 100%;
  border-left: 1px solid black;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD {
  width: 90%;
  height: 100%;
  padding: 5px 15px;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_authorNameContainer__Pa192 {
  width: 100%;
  height: 25%;
  display: flex;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_authorNameContainer__Pa192 h1 {
  margin: 0px 25px;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_authorNameContainer__Pa192 #Comment_avatarSection__11udX {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid black;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_authorNameContainer__Pa192 #Comment_avatarSection__11udX img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_commentContent__3i99Y {
  width: 100%;
  height: 20%;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_commentContent__3i99Y #Comment_likeShareReply__1pKox {
  height: 30px;
  display: flex;
  align-items: center;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_commentContent__3i99Y #Comment_likeShareReply__1pKox i {
  margin: 0px 5px;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_commentContent__3i99Y #Comment_likeShareReply__1pKox p {
  margin: 0px 20px;
  cursor: pointer;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_commentContent__3i99Y #Comment_likeShareReply__1pKox #Comment_replyButton__EXM3n {
  cursor: pointer;
}
#Comment_comment__36Qcp #Comment_commentRightSection__3oFKD #Comment_commentContent__3i99Y #Comment_likeShareReply__1pKox i:nth-child(2) {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

#Comment_commentContainer__2icTw:nth-child(1) {
  border: none;
}
.Events_eventsContainer__3-6YH {
  padding-top: 10rem;
  padding-right: 10vw;
  padding-left: 10vw;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Events_eventsContainer__3-6YH .Events_eventsTitle__3cUNP {
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
}
.Events_eventsContainer__3-6YH .Events_eventsTitle__3cUNP h2 {
  font-weight: 700;
  font-size: clamp(0.5rem, 2vw + 0.75rem, 3rem);
}
.Events_eventsContainer__3-6YH .Events_eventsTitle__3cUNP p {
  line-height: 30px;
  font-size: clamp(0.5rem, 2vw + 0.75rem, 1rem);
  color: #626a77;
  font-family: "Poppins", sans-serif;
}

.Events_eventCard__3qDCl {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0px;
  align-items: center;
  padding: 0.4rem 2.5rem;
  box-shadow: 0px 30px 70px 0px rgba(2, 25, 55, 0.08);
  border-radius: 10px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.Events_eventCard__3qDCl #Events_leftSection__2Ywjl {
  width: 20%;
  height: 100%;
}
.Events_eventCard__3qDCl #Events_leftSection__2Ywjl img {
  width: 100%;
  height: 100%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50% 0 50% 50%;
}
.Events_eventCard__3qDCl .Events_eventContent__3h0CJ {
  width: 80%;
  padding-left: 2rem;
}
.Events_eventCard__3qDCl p {
  line-height: 30px;
  font-size: clamp(0.5rem, 2vw + 0.75rem, 1rem);
  margin-bottom: 15px;
  color: #626a77;
  font-family: "Poppins", sans-serif;
}
.Events_eventCard__3qDCl h2 {
  font-size: clamp(0.5rem, 2vw + 0.75rem, 2rem);
}
.Events_eventCard__3qDCl h5 {
  font-size: clamp(0.5rem, 2vw + 0.75rem, 0.8rem);
}

@media screen and (max-width: 1230px) {
  #Events_leftSection__2Ywjl {
    display: none;
  }
  .Events_eventContent__3h0CJ {
    width: 100% !important;
  }
}
#Home_videoContainer__3YK4u {
  width: 100%;
  height: 100vh;
}
#Home_videoContainer__3YK4u video {
  object-fit: cover;
}

#Home_statisticsWrapper__20E0d {
  width: 100%;
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
#Home_statisticsWrapper__20E0d #Home_heading__1MhTL {
  width: 85%;
  margin: 20px 0px;
}
#Home_statisticsWrapper__20E0d #Home_heading__1MhTL h1 {
  font-weight: 900;
  font-size: 3rem;
  font-family: "Commissioner", sans-serif;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm {
  width: 85%;
  height: 65%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm span {
  right: -5%;
  bottom: -50%;
  position: absolute;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm span img {
  animation: Home_rotateCircle__pA8GT 20s infinite alternate-reverse;
}
@-webkit-keyframes Home_rotateCircle__pA8GT {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Home_rotateCircle__pA8GT {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25px;
  flex-wrap: wrap;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 .Home_scount__3u-xH, #Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 .Home_stext__3fslt {
  width: 100%;
  height: 50%;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 .Home_stext__3fslt {
  padding: 5px 0px;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 .Home_scount__3u-xH h1 {
  font-size: 4rem;
  width: 100%;
  font-family: "Commissioner", sans-serif;
}
#Home_statisticsWrapper__20E0d #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 p {
  width: 100%;
  font-size: 1.3rem;
}

#Home_HEROcarouselContainer__2Jmpi {
  width: 100%;
  height: 88%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
}
#Home_HEROcarouselContainer__2Jmpi #Home_carouselOverlay__29K1B {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF {
  width: 100%;
  height: 100%;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS {
  width: 800%;
  height: 100%;
  display: flex;
  transition: 1.4s linear;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 {
  width: 100%;
  height: 100%;
  position: relative;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 #Home_carouselText__CNqkx {
  width: 50%;
  height: 40%;
  z-index: 1;
  left: 10%;
  color: white;
  bottom: 0%;
  position: absolute;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 #Home_carouselText__CNqkx p {
  margin: 10px 0px;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 #Home_carouselText__CNqkx #Home_carouselCTA__2jaP1 {
  width: 60px;
  color: black;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 #Home_carouselText__CNqkx #Home_carouselCTA__2jaP1 #Home_carouselCTAButton__npk1H {
  margin: 10px 0px;
  padding: 10px 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 #Home_carouselText__CNqkx #Home_carouselCTA__2jaP1 #Home_carouselCTAButton__npk1H p {
  margin: 0px;
}
#Home_HEROcarouselContainer__2Jmpi #Home_HEROcarouselWrapper__28LmF #Home_slider__1phAS .Home_imageContainer__2N9y3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

#Home_aboutUsContianerWrapper__2Kt8e {
  width: 100%;
  min-height: 100vh;
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e, #Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr {
  width: 30%;
  height: 100%;
  position: relative;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e {
  min-width: 400px;
  height: 80vh;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCImageFloater__3C8ZZ {
  width: 120px;
  height: 120px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCImageFloater__3C8ZZ #Home_bigBubble__229Bw {
  width: 95%;
  height: 95%;
  display: flex;
  flex-wrap: wrap;
  line-height: 23px;
  border-radius: 50%;
  position: relative;
  align-content: center;
  justify-content: center;
  background-color: var(--primary-color);
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCImageFloater__3C8ZZ #Home_bigBubble__229Bw h5, #Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCImageFloater__3C8ZZ #Home_bigBubble__229Bw h1 {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCImageFloater__3C8ZZ #Home_smallBubble__2h0FE {
  width: 25%;
  height: 25%;
  right: 1%;
  top: 3%;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  background-color: #fdbf27;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCLSImageContainer__1UuDs {
  width: 85%;
  height: 90%;
  left: 15%;
  top: 10%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCleftSection__3779e #Home_AUCLSImageContainer__1UuDs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr {
  width: 55%;
  display: flex;
  align-content: center;
  justify-content: right;
  flex-wrap: wrap;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_AUmainTitle__2jrQw {
  width: 90%;
  margin: 10px 0px;
  font-weight: 900;
  font-size: 2.5rem;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_experienceTitle__3qlpZ, #Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_briefIntro__yOlSc, #Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_phoneNumber__2j9eG {
  width: 90%;
  margin: 10px 0px;
  text-align: left;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_experienceTitle__3qlpZ {
  font-size: 1.3rem;
  color: var(--primary-color);
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_CTAButtonContainer__2w-6N {
  width: 90%;
  margin: 10px 0px;
}
#Home_aboutUsContianerWrapper__2Kt8e #Home_aboutUsContainer__3_GGe #Home_AUCrightSection__1N8yr #Home_CTAButtonContainer__2w-6N button {
  cursor: pointer;
  color: white;
  border: none;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 50px;
  background-color: var(--primary-color);
}

@media screen and (max-height: 651px) {
  #Home_carouselText__CNqkx {
    bottom: 15% !important;
  }
}
/*Extra small devices (phones, 600px and down) and Small devices (portrait tablets and large phones, 600px and up)
and Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 600px) {
  #Home_videosGrid__37r-m {
    grid-gap: 0.5rem 0rem !important;
    gap: 0.5rem 0rem !important;
    grid-template-columns: 1fr 1fr !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #Home_videosGrid__37r-m {
    grid-template-columns: 1fr 1fr;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  #Home_videosGrid__37r-m {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (max-width: 950px) {
  #Home_statisticsCardContainer__1F2mm {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  #Home_aboutShresthaClubContainer__38Mt- {
    flex-direction: column !important;
  }
  #Home_aboutShresthaClubContainer__38Mt- #Home_ASCcontent__3AdBu, #Home_aboutShresthaClubContainer__38Mt- #Home_ASCVideoContainer__3lgS1 {
    width: 100% !important;
    margin: 25px 0px;
  }
  #Home_aboutShresthaClubContainer__38Mt- #Home_ASCcontent__3AdBu video, #Home_aboutShresthaClubContainer__38Mt- #Home_ASCVideoContainer__3lgS1 video {
    width: 100% !important;
  }
  #Home_aboutShresthaClubContainer__38Mt- #Home_videoWrapper__1-Y1F {
    height: 250px !important;
  }
  #Home_aboutShresthaClubContainer__38Mt- #Home_ASCtext___OD4o {
    width: 100% !important;
  }
}
@media screen and (max-width: 750px) {
  #Home_statisticsCardContainer__1F2mm {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  #Home_AUCleftSection__3779e {
    display: none;
  }
  #Home_AUCrightSection__1N8yr {
    width: 90% !important;
  }
  #Home_GECsubTitle__2S7B5 {
    width: 85% !important;
  }
}
@media screen and (max-width: 600px) {
  .Home_card__91HS8 h1 {
    font-size: 2.7rem !important;
  }
  .Home_card__91HS8 p {
    font-size: 1rem !important;
  }
  #Home_videosGrid__37r-m {
    grid-gap: 0.5rem 0rem !important;
    gap: 0.5rem 0rem !important;
    grid-template-columns: 1fr !important;
  }
  #Home_TSLeftSection__3_eNm {
    display: none !important;
  }
  #Home_TSRightSection__32Gw0 {
    width: 100% !important;
  }
}
@media screen and (max-width: 520px) {
  #Home_heading__1MhTL {
    margin: 10px 0px !important;
  }
  #Home_heading__1MhTL h1 {
    font-size: 2rem !important;
  }
  #Home_carouselText__CNqkx {
    padding: 5px;
    left: 3% !important;
    width: 90% !important;
  }
}
@media screen and (max-width: 500px) {
  #Home_ASCcontent__3AdBu, #Home_ASCVideoContainer__3lgS1 {
    height: 400px !important;
  }
}
@media screen and (max-width: 400px) {
  #Home_TCImageSection__lm83l img {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
  }
  #Home_statisticsCardContainer__1F2mm .Home_card__91HS8 {
    padding: 15px !important;
  }
  .Home_scount__3u-xH h1 {
    font-size: 1.8rem !important;
    width: 100%;
    font-family: "Commissioner", sans-serif;
  }
  .Home_stext__3fslt p {
    font-size: 0.6rem !important;
  }
}
#Footer_footerContainer__1M21y {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0px;
  align-content: space-between;
  position: relative;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topLeftSection__3NwDf {
  width: 40%;
  height: 100%;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topLeftSection__3NwDf img {
  width: 100%;
  height: 90px;
  margin: 16px;
  object-fit: contain;
  float: left;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topLeftSection__3NwDf p {
  text-align: justify;
  color: white;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topRightSection__2a1Nz {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topRightSection__2a1Nz #Footer_socialMediaContainer__3cAsu {
  width: 100%;
  height: 80%;
  padding: 25px 0px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topRightSection__2a1Nz #Footer_socialMediaContainer__3cAsu p {
  font-weight: bold;
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topRightSection__2a1Nz #Footer_socialMediaContainer__3cAsu p a {
  color: white;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerTopSection__1_50L #Footer_topRightSection__2a1Nz #Footer_socialMediaContainer__3cAsu p a i {
  margin: 0px 10px;
  font-size: 2.5rem;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerBottomSection__3bZcc {
  width: 100%;
  height: 15%;
  display: flex;
  margin: 25px 0px;
  align-items: center;
  position: relative;
}
#Footer_footerContainer__1M21y #Footer_footerWrapper__21S2A #Footer_footerBottomSection__3bZcc::after {
  content: "";
  display: block;
  width: 80%;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -40%;
  height: 1px;
  background: radial-gradient(ellipse at center, rgba(222, 228, 239, 0.8) 0, rgba(255, 255, 255, 0) 75%);
}

@media screen and (max-width: 900px) {
  #Footer_footerTopSection__1_50L {
    flex-direction: column;
  }
  #Footer_footerTopSection__1_50L #Footer_topLeftSection__3NwDf, #Footer_footerTopSection__1_50L #Footer_topRightSection__2a1Nz {
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  #Footer_footerWrapper__21S2A {
    width: 100% !important;
  }
  #Footer_topLeftSection__3NwDf {
    width: 40%;
    height: 100%;
  }
  #Footer_topLeftSection__3NwDf img {
    width: 100% !important;
    margin: 0px !important;
  }
  #Footer_socialMediaContainer__3cAsu p {
    font-size: 1rem !important;
  }
  #Footer_socialMediaContainer__3cAsu p a i {
    margin: 0px 10px;
    font-size: 1.5rem !important;
  }
  #Footer_followUs__31-5K {
    margin: 0px !important;
    white-space: nowrap;
  }
}
@media screen and (max-width: 400px) {
  #Footer_socialMediaContainer__3cAsu {
    flex-direction: column;
  }
  #Footer_socialMediaContainer__3cAsu p {
    width: 100%;
    font-size: 0.6rem !important;
  }
  #Footer_socialMediaContainer__3cAsu p a i {
    margin: 0px 10px;
    font-size: 1.5rem !important;
  }
}
#EventCards_eventCardContainer__8fxzw {
  width: 100%;
  color: white;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0px;
  background-color: #001846;
}
#EventCards_eventCardContainer__8fxzw #EventCards_heading__eD-fm {
  width: 100%;
  display: flex;
  margin: 0px 0px 20px 0px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
#EventCards_eventCardContainer__8fxzw #EventCards_heading__eD-fm h1 {
  font-size: 3rem;
  font-weight: 900;
  position: relative;
  font-family: "Commissioner", sans-serif;
}
#EventCards_eventCardContainer__8fxzw #EventCards_heading__eD-fm h1 #EventCards_underline__2COqe {
  width: 100%;
  left: 5%;
  bottom: -20%;
  position: absolute;
  border: 4px solid var(--primary-color);
}
#EventCards_eventCardContainer__8fxzw #EventCards_videoGridWrapper__2-9Lr {
  width: 85%;
  justify-content: center;
}
#EventCards_eventCardContainer__8fxzw #EventCards_videoGridWrapper__2-9Lr #EventCards_videosGrid__1Nqfh {
  width: 90%;
  height: 100%;
  display: grid;
  grid-gap: 1rem 0rem;
  gap: 1rem 0rem;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.EventCards_eventCard__1B5pY {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0px;
  padding-top: 2rem;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding: 0.4rem 2.5rem;
  border: 1px solid white;
  box-shadow: 0px 30px 70px 0px rgba(2, 25, 55, 0.08);
}
.EventCards_eventCard__1B5pY #EventCards_leftSection__1YoEH {
  width: 20%;
  height: 100%;
}
.EventCards_eventCard__1B5pY #EventCards_leftSection__1YoEH img {
  width: 100%;
  height: 100%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.EventCards_eventCard__1B5pY .EventCards_eventContent__2F1Yx {
  width: 80%;
  padding-left: 2rem;
}
.EventCards_eventCard__1B5pY p {
  line-height: 30px;
  font-size: clamp(0.5rem, 2vw + 0.75rem, 1rem);
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}
.EventCards_eventCard__1B5pY h2 {
  font-size: clamp(0.5rem, 2vw + 0.75rem, 2rem);
}
.EventCards_eventCard__1B5pY h5 {
  font-size: clamp(0.5rem, 2vw + 0.75rem, 0.8rem);
}

@media screen and (max-width: 1230px) {
  #EventCards_leftSection__1YoEH {
    display: none;
  }
  .EventCards_eventContent__2F1Yx {
    width: 100% !important;
  }
}
@media screen and (max-width: 750px) {
  #EventCards_eventCardContainer__8fxzw {
    padding: 70px 0px;
  }
}
@media screen and (max-width: 500px) {
  #EventCards_heading__eD-fm h1 {
    font-size: 2rem !important;
  }
}
#WhatDoWeOffer_Wrapper__CxJZT {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 {
  width: 85%;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_heading__5hgfH {
  width: 100%;
  height: 15%;
  margin: 20px 0px;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_heading__5hgfH h1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 3rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  position: relative;
  font-family: "Commissioner", sans-serif;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_heading__5hgfH h1 #WhatDoWeOffer_circle1__2nIcF {
  z-index: -1;
  width: 100px;
  height: 100px;
  left: -7%;
  bottom: -25%;
  position: absolute;
  border-radius: 50px;
  animation: WhatDoWeOffer_upNdown__3Ams7 ease-out 6s infinite reverse;
  background-color: rgb(204, 255, 255);
}
@-webkit-keyframes WhatDoWeOffer_upNdown__3Ams7 {
  0% {
    left: -7%;
    bottom: -25%;
  }
  50% {
    bottom: 0%;
    left: 0%;
  }
  95% {
    bottom: -15%;
    left: -5%;
  }
}
@keyframes WhatDoWeOffer_upNdown__3Ams7 {
  0% {
    left: -7%;
    bottom: -25%;
  }
  50% {
    bottom: 0%;
    left: 0%;
  }
  95% {
    bottom: -15%;
    left: -5%;
  }
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_innerContainer__3Q2Vc {
  width: 100%;
  height: 95vh;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_innerContainer__3Q2Vc .WhatDoWeOffer_row__2l-4i {
  width: 100%;
  height: 50%;
  display: flex;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_innerContainer__3Q2Vc .WhatDoWeOffer_row__2l-4i #WhatDoWeOffer_bigsec__2EZCQ {
  width: 66.67%;
  height: 100%;
}
#WhatDoWeOffer_Wrapper__CxJZT #WhatDoWeOffer_container__1BrM0 #WhatDoWeOffer_innerContainer__3Q2Vc .WhatDoWeOffer_row__2l-4i .WhatDoWeOffer_smlsec__1Fv-q {
  width: 33.33%;
  height: 100%;
}

#WhatDoWeOffer_blockContainer__1KA1k {
  width: 100%;
  height: 100%;
  transition: 1s;
  overflow: hidden;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  border: 4px solid white;
}
#WhatDoWeOffer_blockContainer__1KA1k #WhatDoWeOffer_blockTextContainer__1pAqz {
  width: 100%;
  color: white;
  height: 100%;
  display: flex;
  transition: 0.4s;
  flex-wrap: wrap;
  align-content: end;
  justify-content: end;
  padding: 25px;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2595237924) 60%, rgb(0, 0, 0) 95%);
}
#WhatDoWeOffer_blockContainer__1KA1k #WhatDoWeOffer_blockTextContainer__1pAqz #WhatDoWeOffer_textHeading__37zxy {
  width: 95%;
}
#WhatDoWeOffer_blockContainer__1KA1k #WhatDoWeOffer_blockTextContainer__1pAqz #WhatDoWeOffer_textHeading__37zxy h1 {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 300;
  color: yellow;
}
#WhatDoWeOffer_blockContainer__1KA1k #WhatDoWeOffer_blockTextContainer__1pAqz #WhatDoWeOffer_text__2WWtc {
  width: 95%;
}
#WhatDoWeOffer_blockContainer__1KA1k #WhatDoWeOffer_blockTextContainer__1pAqz #WhatDoWeOffer_text__2WWtc p {
  font-size: 1.3rem;
}

@media screen and (max-width: 800px) {
  #WhatDoWeOffer_innerContainer__3Q2Vc {
    width: 100%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .WhatDoWeOffer_row__2l-4i {
    flex-direction: column;
  }
  .WhatDoWeOffer_row__2l-4i #WhatDoWeOffer_bigsec__2EZCQ, .WhatDoWeOffer_row__2l-4i .WhatDoWeOffer_smlsec__1Fv-q {
    width: 100% !important;
  }
}
@media screen and (max-width: 550px) {
  #WhatDoWeOffer_textHeading__37zxy h1 {
    font-size: 1.5rem !important;
  }
  #WhatDoWeOffer_text__2WWtc {
    width: 95%;
  }
  #WhatDoWeOffer_text__2WWtc p {
    font-size: 1rem !important;
  }
  #WhatDoWeOffer_heading__5hgfH h1 {
    font-size: 2.5rem !important;
  }
  #WhatDoWeOffer_heading__5hgfH h1 #WhatDoWeOffer_circle1__2nIcF {
    width: 80px !important;
    height: 80px !important;
    bottom: -20% !important;
  }
  @-webkit-keyframes WhatDoWeOffer_upNdown__3Ams7 {
    0% {
      left: -7%;
      bottom: -20% !important;
    }
    50% {
      bottom: 0%;
      left: 0%;
    }
    95% {
      bottom: -15%;
      left: -5%;
    }
  }
  @keyframes WhatDoWeOffer_upNdown__3Ams7 {
    0% {
      left: -7%;
      bottom: -20% !important;
    }
    50% {
      bottom: 0%;
      left: 0%;
    }
    95% {
      bottom: -15%;
      left: -5%;
    }
  }
}
@media screen and (max-width: 400px) {
  #WhatDoWeOffer_heading__5hgfH {
    height: 8% !important;
  }
  #WhatDoWeOffer_heading__5hgfH h1 {
    font-size: 2rem !important;
  }
  #WhatDoWeOffer_heading__5hgfH h1 #WhatDoWeOffer_circle1__2nIcF {
    width: 40px !important;
    height: 40px !important;
  }
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq {
  width: 100%;
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCHeading__LCOW2 {
  width: 100%;
  margin: 25px 0px;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCHeading__LCOW2 h1 {
  font-weight: 900;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px;
  font-size: 3rem;
  font-family: "Commissioner", sans-serif;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCHeading__LCOW2 span {
  font-weight: 700;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0px;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ #AboutShresthaClub_ASCcontent__17e7I {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-evenly;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ #AboutShresthaClub_ASCcontent__17e7I #AboutShresthaClub_ASCtext__-rRuQ {
  width: 90%;
  font-size: 1rem;
  text-align: center;
  text-align: justify;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ #AboutShresthaClub_ASCVideoContainer__3B1Go {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ #AboutShresthaClub_ASCVideoContainer__3B1Go img {
  width: 100%;
  object-fit: contain;
  max-height: 350px;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ #AboutShresthaClub_ASCVideoContainer__3B1Go video {
  width: 100%;
  height: 470px;
}
#AboutShresthaClub_aboutShresthaClubWrapper__2VZTq #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ:nth-child(3) #AboutShresthaClub_ASCcontent__17e7I {
  justify-content: flex-end;
}

@media screen and (max-width: 800px) {
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 {
    flex-direction: column;
  }
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 .AboutShresthaClub_sec__13hKZ:nth-child(3) {
    flex-direction: column-reverse;
  }
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCcontent__17e7I, #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCVideoContainer__3B1Go {
    width: 100% !important;
    padding: 15px 0px;
  }
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCVideoContainer__3B1Go video {
    margin: 30px 0px;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCHeading__LCOW2 {
    width: 100%;
  }
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCHeading__LCOW2 h1 {
    margin: 25px 0px;
  }
  #AboutShresthaClub_aboutShresthaClubContainer__3ud28 #AboutShresthaClub_ASCtext__-rRuQ {
    width: 100% !important;
  }
}
@media screen and (max-width: 700px) {
  #AboutShresthaClub_ASCHeading__LCOW2 h1 {
    font-size: 2rem !important;
  }
  #AboutShresthaClub_ASCHeading__LCOW2 span p {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 600px) {
  #AboutShresthaClub_ASCtext__-rRuQ {
    font-size: 0.8rem !important;
  }
}
@media screen and (max-width: 500px) {
  #AboutShresthaClub_ASCHeading__LCOW2 {
    margin: 0px !important;
  }
  #AboutShresthaClub_ASCHeading__LCOW2 h1 {
    font-size: 1.5rem !important;
  }
  #AboutShresthaClub_ASCHeading__LCOW2 span p {
    font-size: 0.7rem !important;
  }
}
#GetEmpowered_GEWrapper__3hDYR {
  width: 100%;
  margin: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_GECurve__2jzEK {
  position: absolute;
  right: 0%;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_GESquare__1ZNG3 {
  position: absolute;
  bottom: 440px;
  right: 75px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation: GetEmpowered_squareAnimation__2l5Uw 9s ease-in-out 2s infinite alternate;
          animation: GetEmpowered_squareAnimation__2l5Uw 9s ease-in-out 2s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  transition: all 1s ease 3s;
  -webkit-user-select: none;
          user-select: none;
}
@-webkit-keyframes GetEmpowered_squareAnimation__2l5Uw {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
            transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: 0.5;
  }
  25% {
    -webkit-transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
            transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
            transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
    opacity: 0.8;
  }
  75% {
    -webkit-transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
            transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
            transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
    opacity: 0.9;
  }
}
@keyframes GetEmpowered_squareAnimation__2l5Uw {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
            transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: 0.5;
  }
  25% {
    -webkit-transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
            transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
            transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
    opacity: 0.8;
  }
  75% {
    -webkit-transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
            transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
            transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
    opacity: 0.9;
  }
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_GETriangle__2LMj0 {
  position: absolute;
  bottom: 425px;
  right: 125px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation: GetEmpowered_triangleAnimation__1EZeU 9s ease-in-out 2s infinite alternate;
          animation: GetEmpowered_triangleAnimation__1EZeU 9s ease-in-out 2s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  transition: all 7s ease 2s;
  -webkit-user-select: none;
          user-select: none;
}
@-webkit-keyframes GetEmpowered_triangleAnimation__1EZeU {
  0% {
    -webkit-transform: translateY(10px) translateX(0) rotate(0) scale(1);
            transform: translateY(10px) translateX(0) rotate(0) scale(1);
    opacity: 0.9;
  }
  20% {
    -webkit-transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
            transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
    opacity: 0.8;
  }
  40% {
    -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
            transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
    opacity: 0.8;
  }
  40% {
    -webkit-transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
            transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
            transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
            transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
    opacity: 0.7;
  }
}
@keyframes GetEmpowered_triangleAnimation__1EZeU {
  0% {
    -webkit-transform: translateY(10px) translateX(0) rotate(0) scale(1);
            transform: translateY(10px) translateX(0) rotate(0) scale(1);
    opacity: 0.9;
  }
  20% {
    -webkit-transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
            transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
    opacity: 0.8;
  }
  40% {
    -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
            transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
    opacity: 0.8;
  }
  40% {
    -webkit-transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
            transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
            transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
            transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
    opacity: 0.7;
  }
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu {
  width: 100%;
  height: 75%;
  display: flex;
  align-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECHeadingContainer__1ywH5 {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECHeadingContainer__1ywH5 #GetEmpowered_GECTitle__2kW-p {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECHeadingContainer__1ywH5 #GetEmpowered_GECTitle__2kW-p h1 {
  font-weight: 900;
  font-size: 2.5rem;
  font-family: "Commissioner", sans-serif;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECHeadingContainer__1ywH5 #GetEmpowered_GECsubTitle__1ZGYb {
  width: 50%;
  margin: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 50px;
  row-gap: 50px;
  justify-content: center;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn .GetEmpowered_GECCardContainer__3Bw5B {
  width: 23%;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn .GetEmpowered_GECCardContainer__3Bw5B .GetEmpowered_GECCiconContainer__mHbEs {
  width: 100%;
  height: 50%;
  max-height: 90px;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn .GetEmpowered_GECCardContainer__3Bw5B .GetEmpowered_GECCiconContainer__mHbEs img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn .GetEmpowered_GECCardContainer__3Bw5B .GetEmpowered_GECCTitleCotnainer__2jGjI {
  width: 100%;
  height: 20%;
  text-align: center;
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn .GetEmpowered_GECCardContainer__3Bw5B .GetEmpowered_GECCTitleCotnainer__2jGjI h2 {
  color: rgb(80, 80, 80);
}
#GetEmpowered_GEWrapper__3hDYR #GetEmpowered_getEmpoweredContainer__l5jlu #GetEmpowered_GECcardsContainer__3-LLn .GetEmpowered_GECCardContainer__3Bw5B .GetEmpowered_GECCSubtitleContainer__2mSoY {
  width: 80%;
  height: 30%;
  text-align: center;
}

@media screen and (max-width: 500px) {
  #GetEmpowered_GECTitle__2kW-p h1 {
    font-size: 2rem !important;
    text-align: center !important;
  }
  #GetEmpowered_GETriangle__2LMj0 {
    display: none;
  }
  #GetEmpowered_GESquare__1ZNG3 {
    display: none;
  }
  #GetEmpowered_GECurve__2jzEK {
    width: 20% !important;
  }
}
#Testimonial_testimonialSectionWrapper__323-T {
  width: 100%;
  min-height: 75%;
  display: flex;
  margin: 50px 0px;
  justify-content: center;
  background-color: var(--primary-color);
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 {
  width: 90%;
  display: flex;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSanimationContainer__3vs1H {
  width: 100%;
  height: 100%;
  position: absolute;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSanimationContainer__3vs1H #Testimonial_circleAnimation1__sWqpm {
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.1;
  position: absolute;
  left: -150px;
  bottom: -150px;
  width: 510px;
  height: 510px;
  -webkit-animation: Testimonial_circleAnimation__rmL1y 7s linear infinite;
  animation: Testimonial_circleAnimation__rmL1y 7s linear infinite;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSanimationContainer__3vs1H #Testimonial_circleAnimation2__2ZNEU {
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.08;
  position: absolute;
  left: 258px;
  bottom: -200px;
  width: 330px;
  height: 330px;
  -webkit-animation: Testimonial_circleAnimation__rmL1y 5s linear infinite;
  animation: Testimonial_circleAnimation__rmL1y 5s linear infinite;
}
@-webkit-keyframes Testimonial_circleAnimation__rmL1y {
  0%, 100% {
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
@keyframes Testimonial_circleAnimation__rmL1y {
  0%, 100% {
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSLSText__PM8Ra {
  width: 100%;
  position: relative;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSLSText__PM8Ra h1 {
  color: white;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: 4px;
  font-family: "Commissioner", sans-serif;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSLSText__PM8Ra img {
  right: 0px;
  bottom: 30%;
  position: absolute;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_TcarouselContainer__2MRYs {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_TcarouselContainer__2MRYs #Testimonial_TcarouselWrapper__nZFlY {
  width: 100%;
  height: 100%;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 25px 0px;
  align-content: space-between;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCcontent__r1pry {
  width: 100%;
  padding: 15px;
  color: white;
  overflow-y: auto;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCcontent__r1pry p {
  font-size: 2rem;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCcontent__r1pry::-webkit-scrollbar {
  width: 5px;
  background-color: var(--primary-color);
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCcontent__r1pry::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 50px;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCInfo__d9IGM {
  width: 100%;
  display: flex;
  align-content: space-between;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCInfo__d9IGM #Testimonial_TCImageSection__2i2Cr {
  padding: 13px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCInfo__d9IGM #Testimonial_TCImageSection__2i2Cr img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCInfo__d9IGM #Testimonial_TCNameSection__2M0hv {
  width: 70%;
  height: 100%;
  padding: 13px;
  color: white;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCInfo__d9IGM #Testimonial_TCNameSection__2M0hv .Testimonial_testyName__1lGaT {
  font-size: 1.8rem;
  font-weight: 700;
}
#Testimonial_testimonialSectionWrapper__323-T #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSRightSection__2UJo8 #Testimonial_testimonialContainer__33E9j #Testimonial_TCInfo__d9IGM #Testimonial_TCNameSection__2M0hv .Testimonial_testyDesig__tx7TN {
  font-size: 1.4rem;
}

@media screen and (max-width: 600px) {
  #Testimonial_testimonialSectionContainer__32fo1 {
    flex-direction: column;
  }
  #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO {
    height: 15% !important;
    width: 100% !important;
  }
  #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSanimationContainer__3vs1H {
    display: none !important;
  }
  #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSLSText__PM8Ra {
    position: relative;
  }
  #Testimonial_testimonialSectionContainer__32fo1 #Testimonial_TSLeftSection__1kGZO #Testimonial_TSLSText__PM8Ra h1 {
    text-align: center;
    margin: 20px 0px;
    width: 100% !important;
    font-size: 1.5rem !important;
    font-weight: 500;
    letter-spacing: 0px !important;
  }
  #Testimonial_TCImageSection__2i2Cr img {
    width: 70px !important;
    height: 70px !important;
  }
  #Testimonial_TSRightSection__2UJo8 {
    width: 100% !important;
    height: 85% !important;
  }
  #Testimonial_TCcontent__r1pry p {
    font-size: 1.2rem !important;
  }
  .Testimonial_testyName__1lGaT {
    font-size: 1.4rem !important;
    font-weight: 700;
  }
  .Testimonial_testyDesig__tx7TN {
    font-size: 1rem !important;
  }
}
#Carousel_slider__1Zq5m {
  height: 100%;
  display: flex;
  transition: 1s ease-in;
}
#Carousel_slider__1Zq5m .Carousel_imageContainer__1YJi8 {
  width: 100%;
  height: 100%;
}
#Carousel_slider__1Zq5m .Carousel_imageContainer__1YJi8 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#TeamMembers_teamMembersWrapper__Jk6vG {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE {
  width: 100%;
  height: 40vh;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_design__17W_n, #TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_heading__1v86K, #TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_subTitle__1B5Bb {
  width: 100%;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_design__17W_n {
  display: flex;
  padding: 3px;
  justify-content: center;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_design__17W_n .TeamMembers_bar__n5J3I {
  width: 3px;
  height: 15px;
  margin: 0px 3px;
  border: 1px solid #00b3ff;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  background-color: #00b3ff;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_heading__1v86K {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #183a39;
  text-align: center;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_upperSection__1rkbE #TeamMembers_subTitle__1B5Bb {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #6a858c;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(118deg, rgb(32, 155, 212) 0%, rgb(119, 47, 232) 100%);
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb {
  top: -25%;
  width: 23%;
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_image__27qNa, #TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_name__2xqcw {
  width: 100%;
  height: 50%;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_image__27qNa {
  display: flex;
  justify-content: center;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_image__27qNa img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 8px solid white;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_name__2xqcw {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  text-align: center;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_name__2xqcw p:nth-child(1) {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}
#TeamMembers_teamMembersWrapper__Jk6vG #TeamMembers_teamMemberContainer__16TWd #TeamMembers_lowerSection__2dyut #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_name__2xqcw p:nth-child(2) {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  #TeamMembers_lowerSectionWrapper__I2lcW {
    width: 100% !important;
  }
}
@media screen and (max-width: 850px) {
  #TeamMembers_lowerSectionWrapper__I2lcW {
    grid-row-gap: 35px;
    row-gap: 35px;
    flex-direction: column;
    width: 100% !important;
  }
  #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb {
    padding: 15px;
    top: 0% !important;
    width: 100% !important;
    min-height: auto !important;
    flex-wrap: nowrap !important;
  }
  #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_name__2xqcw, #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_image__27qNa {
    height: 100% !important;
  }
  #TeamMembers_lowerSectionWrapper__I2lcW .TeamMembers_card__XCMJb .TeamMembers_image__27qNa {
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  #TeamMembers_upperSection__1rkbE {
    margin: 25px 0px;
    height: auto !important;
  }
  #TeamMembers_heading__1v86K {
    font-size: 0.9rem !important;
  }
  #TeamMembers_subTitle__1B5Bb {
    padding: 0px 15px;
    font-size: 0.7rem !important;
  }
}
.EssentialsCard_essentialCard__2qV5Q {
  width: 15em;
  height: 100%;
  padding: 10px;
  border-radius: 7px;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  text-align: center;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_imageContainer__3-Q7x {
  width: 100%;
  height: 100%;
  min-height: 240px;
  overflow: hidden;
  border-radius: 5%;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_imageContainer__3-Q7x img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_cardContent__1MADI {
  height: 15%;
  width: 100%;
  overflow: hidden;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_cardContent__1MADI #EssentialsCard_title__1DYpg {
  width: 100%;
  text-align: center;
  padding: 3px 0px;
  margin: 5px 0px;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_cardContent__1MADI #EssentialsCard_title__1DYpg h3 {
  font-family: "Poppins", sans-serif;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_cardContent__1MADI .EssentialsCard_buttonContainer__1nR_8 {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  align-items: center;
}
.EssentialsCard_essentialCard__2qV5Q .EssentialsCard_cardContent__1MADI .EssentialsCard_buttonContainer__1nR_8 button {
  cursor: pointer;
  height: 100%;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 4px;
  background: #C8102E;
}
.Essentials_essentialContainer__K1-zA {
  padding-top: 10rem;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Essentials_essentialContainer__K1-zA .Essentials_essentialTitle__AV0KF {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 20px;
}
.Essentials_essentialContainer__K1-zA .Essentials_essentialTitle__AV0KF #Essentials_title__31TLB {
  font-size: clamp(1rem, 2vw + 0.75rem, 3rem);
}
.Essentials_essentialContainer__K1-zA .Essentials_essentialTitle__AV0KF #Essentials_subTitle__md10M {
  font-size: clamp(0.5rem, 2vw + 0.75rem, 2rem);
}
.Essentials_essentialContainer__K1-zA .Essentials_essentialContent__eQZFw {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 {
  padding-top: 5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF {
  padding: 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsLeft__2B0NR {
  width: 35vw;
  padding: 20px;
  min-width: 325px;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsLeft__2B0NR img {
  width: 100%;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsLeft__2B0NR h2 {
  text-align: left;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsLeft__2B0NR button {
  cursor: pointer;
  height: 100%;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 4px;
  background: #C8102E;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsRight__G5DFL {
  width: 35vw;
  min-width: 325px;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsRight__G5DFL .EssentialsDetail_userComment__3y6QQ p, .EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsRight__G5DFL .EssentialsDetail_userComment__3y6QQ textarea, .EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsRight__G5DFL .EssentialsDetail_userComment__3y6QQ input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: clamp(0.5rem, 2vw + 0.75rem, 1rem);
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsRight__G5DFL .EssentialsDetail_userComment__3y6QQ textarea {
  min-height: 130px;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_upperSection__uvtgF .EssentialsDetail_detailsRight__G5DFL .EssentialsDetail_userComment__3y6QQ button {
  padding: 4px 24px;
  background: #232323;
  border-color: #232323;
  color: #fff;
  font-size: clamp(0.5rem, 2vw + 0.75rem, 1rem);
  font-weight: bold;
  cursor: pointer;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_lowerSection__3JIfF {
  background-color: #1c1c1c;
  width: 100%;
}
.EssentialsDetail_essentialsDetailsContainer__2iai3 .EssentialsDetail_lowerSection__3JIfF .EssentialsDetail_essentialContent___fbch {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
#EventDetail_Wrapper__1DSL4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY {
  width: 100%;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-position-x: right;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  color: white;
  padding: 40px;
  align-content: end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0550419997) 0%, rgb(0, 0, 0) 90%);
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventTitle__36Woz, #EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventLocationNDate__AXAVv, #EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventSubtitle__18Qjn {
  width: 100%;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventTitle__36Woz h1 {
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: 3px;
  font-family: "Commissioner", sans-serif;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventLocationNDate__AXAVv {
  display: flex;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventLocationNDate__AXAVv #EventDetail_location__N9N7l, #EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventLocationNDate__AXAVv #EventDetail_date__2SVwb {
  display: flex;
  align-items: center;
  margin: 0px 10px;
  margin-left: 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventLocationNDate__AXAVv #EventDetail_location__N9N7l i, #EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_banner__CD4PT #EventDetail_bannerText__Y4Zj7 #EventDetail_eventLocationNDate__AXAVv #EventDetail_date__2SVwb i {
  margin: 0px 10px;
  margin-left: 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF {
  width: 60%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A {
  width: 85%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_eventDetailText__3_6oe {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-content: space-evenly;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_eventDetailText__3_6oe #EventDetail_eventHeading__1vf4u {
  width: 100%;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_eventDetailText__3_6oe #EventDetail_eventHeading__1vf4u h1 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 3rem;
  font-weight: 900;
  margin: 25px 0px;
  font-family: "Commissioner", sans-serif;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_eventDetailText__3_6oe #EventDetail_ASCtext__MMujk {
  width: 100%;
  height: 75%;
  text-align: justify;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_videoSection__2xZEc {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw {
  width: 100%;
  height: 70%;
  margin-bottom: 50px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESTitle__3tAYY {
  height: 15%;
  margin: 25px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESCards__2d1lY {
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESCards__2d1lY #EventDetail_ESCard__S-lW8 {
  width: 27%;
  height: 180px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESCards__2d1lY #EventDetail_ESCard__S-lW8 #EventDetail_icon__1Tt56 {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESCards__2d1lY #EventDetail_ESCard__S-lW8 #EventDetail_icon__1Tt56 img {
  width: 70%;
  object-fit: contain;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESCards__2d1lY #EventDetail_ESCard__S-lW8 #EventDetail_icon__1Tt56 i {
  font-size: 5rem;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF #EventDetail_leftSectionWrapper__kgh4A #EventDetail_extraSection__1fzyw #EventDetail_ESCards__2d1lY #EventDetail_ESCard__S-lW8 #EventDetail_cardText__86f1q {
  width: 80%;
  text-align: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik {
  width: 40%;
  display: flex;
  justify-content: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H {
  width: 80%;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 {
  width: 100%;
  height: 500px;
  display: flex;
  padding: 25px;
  flex-wrap: wrap;
  transition: 0.4s;
  border-radius: 7px;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
  align-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_Rtitle__7doXZ {
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0px;
  border-radius: 5px;
  justify-content: center;
  background-color: white;
  padding: relative;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_Rtitle__7doXZ i {
  z-index: 1;
  color: black;
  font-size: 1.6rem;
  margin: 0px 10px;
  position: relative;
  background-color: white !important;
}
@-webkit-keyframes EventDetail_upNdown__1OQWO {
  from {
    width: 0%;
    height: 0%;
    opacity: 1;
  }
  to {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes EventDetail_upNdown__1OQWO {
  from {
    width: 0%;
    height: 0%;
    opacity: 1;
  }
  to {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_Rtitle__7doXZ h1 {
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  font-family: "Commissioner", sans-serif;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_inputFields__2XB7k {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_inputFields__2XB7k .EventDetail_inpfield__2LuVw {
  width: 90%;
  height: 12%;
  position: relative;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_inputFields__2XB7k .EventDetail_inpfield__2LuVw input {
  width: 100%;
  padding: 10px;
  height: 100%;
  font-size: 1.1rem;
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_registerContainer__3EJx7 #EventDetail_inputFields__2XB7k #EventDetail_submitButton__21Ha5 {
  width: 100%;
  height: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- {
  width: 100%;
  margin: 40px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- #EventDetail_speakersTitle__3FgPz {
  margin: 30px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- #EventDetail_speakersTitle__3FgPz h1 {
  font-size: 3rem;
  font-weight: 900;
  font-family: "Commissioner", sans-serif;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- .EventDetail_speaker__1Pqik {
  width: 100%;
  height: 110px;
  margin: 20px 0px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- .EventDetail_speaker__1Pqik .EventDetail_speakerIconContainer__1N798 {
  width: 100px;
  height: 100px;
  padding: 3px;
  overflow: hidden;
  border-radius: 50%;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- .EventDetail_speaker__1Pqik .EventDetail_speakerIconContainer__1N798 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- .EventDetail_speaker__1Pqik .EventDetail_speakerDetailsContainer__1DEjD {
  width: 70%;
  height: 100%;
  padding: 6px 15px;
}
#EventDetail_Wrapper__1DSL4 #EventDetail_container__1caKY #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_rightSectionWrapper__LSw6H #EventDetail_speakersSection__Y85F- .EventDetail_speaker__1Pqik .EventDetail_speakerDetailsContainer__1DEjD h1 {
  font-size: 1.5rem;
}

@media screen and (max-width: 930px) {
  #EventDetail_contentContainer__10ggC {
    flex-direction: column;
  }
  #EventDetail_contentContainer__10ggC #EventDetail_leftSection__17sYF, #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik {
    width: 100% !important;
  }
  #EventDetail_contentContainer__10ggC #EventDetail_rightSection__2VNik #EventDetail_registerContainer__3EJx7 {
    -webkit-transform: translateY(0px) !important;
            transform: translateY(0px) !important;
    margin: 40px 0px;
  }
}
@media screen and (max-width: 500px) {
  #EventDetail_videoSection__2xZEc {
    margin: 10px 0px !important;
  }
  #EventDetail_eventLocationNDate__AXAVv {
    flex-direction: column;
  }
  #EventDetail_eventSubttitle__2SJw6 {
    margin: 10px 0px;
  }
  #EventDetail_Rtitle__7doXZ i {
    display: none !important;
  }
}
