#slider{
    height: 100%;
    display: flex;
    transition: 1s ease-in;

    .imageContainer{
        width: 100%;
        height: 100%;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }
}