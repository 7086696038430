.essentialCard {
    width: 15em;
    height: 100%;
    padding: 10px;
    border-radius: 7px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    text-align: center;

    .imageContainer {
        width: 100%;
        height: 100%;
        min-height: 240px;
        overflow: hidden;
        border-radius: 5%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .cardContent {
        height: 15%;
        width: 100%;
        overflow: hidden;
        

        #title{
            width: 100%;
            text-align: center;
            padding: 3px 0px;
            margin: 5px 0px;
    
            h3{
                font-family: 'Poppins', sans-serif;
            }
        }

        .buttonContainer {
            width: 100%;
            height: max-content;
            align-items: center;
            
            button{
                cursor: pointer;
                height: 100%;
                border: none;
                color: white;
                font-size: 1rem;
                font-weight: 600;
                padding: 8px 20px;
                border-radius: 4px;
                background: #C8102E;
            }
        }
    }


}