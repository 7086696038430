@mixin alignment{
    display: flex;
    align-items: center;
    justify-content: center;
}

#Wrapper{
    width: 100%;
    @include alignment;

    #container{
        width: 85%;

        #heading{
            width: 100%;
            height: 15%;
            margin: 20px 0px;

            h1{
                width: fit-content;
                font-size: 3rem;
                font-weight: 900;
                display: flex;
                align-items: center;
                position: relative;
                font-family: 'Commissioner', sans-serif;
                
                #circle1{
                    z-index: -1;
                    width: 100px;
                    height: 100px;
                    left: -7%;
                    bottom: -25%;
                    position: absolute;
                    border-radius: 50px;
                    animation: upNdown ease-out 6s infinite reverse;
                    background-color: rgb(204, 255, 255);
                }

                @keyframes upNdown {
                    0%{
                        left: -7%;
                        bottom: -25%;
                    } 50%{
                        bottom: 0%;
                        left: 0%;
                    } 95%{
                        bottom: -15%;
                        left: -5%;
                    }
                }
            }
        }

        #innerContainer{
            width: 100%;            
            height: 95vh;
            

            .row{
                width: 100%;
                height: 50%;
                display: flex;
                #bigsec{
                    width: 66.67%;
                    height: 100%;
                    
                }
                .smlsec{
                    width: 33.33%;
                    height: 100%;
                    
                }
            }
        }
    }
}

#blockContainer{
    width: 100%;
    height: 100%;
    transition: 1s;
    overflow: hidden;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    border: 4px solid white;    

    #blockTextContainer{
        width: 100%;
        color: white;
        height: 100%;
        display: flex;
        // top: 100%;
        transition: 0.4s;
        flex-wrap: wrap;
        align-content: end;
        justify-content: end;        
        padding: 25px;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0.2595237924271271) 60%, rgba(0,0,0,1) 95%);
        #textHeading{
            width: 95%;
            h1{
                text-transform: uppercase;
                font-size: 2rem;
                font-weight: 300;
                color: yellow;
            }
        }
        #text{
            width: 95%;
            p{
                font-size: 1.3rem;
            }
        }
    }

   
}
// #blockContainer:hover #blockTextContainer{
//     top: 0% !important;
// }




@media screen and (max-width: 800px){
    #innerContainer{
        width: 100%;            
        height: fit-content !important;
    }

    .row{
        flex-direction: column;
        #bigsec, .smlsec{
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 550px){
    #textHeading{
        h1{
            font-size: 1.5rem !important;
        }
    }
    #text{
        width: 95%;
        p{
            font-size: 1rem !important; 
        }
    }

    #heading{
        h1{
            font-size: 2.5rem !important;
            #circle1{
                width: 80px !important;
                height: 80px !important;
                bottom: -20% !important;
            }
            @keyframes upNdown {
                0%{
                    left: -7%;
                    bottom: -20% !important;
                } 50%{
                    bottom: 0%;
                    left: 0%;
                } 95%{
                    bottom: -15%;
                    left: -5%;
                }
            }
        }
    }
}

@media screen and (max-width: 400px){
    #heading{
        height: 8% !important;
        h1{
            font-size: 2rem !important;
            #circle1{
                width: 40px !important;
                height: 40px !important;
            }
        }
    }
}