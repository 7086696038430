#testimonialSectionWrapper{
    width: 100%;
    min-height: 75%;
    display: flex;
    margin: 50px 0px;
    justify-content: center;
    background-color: var(--primary-color);

    #testimonialSectionContainer{
        width: 90%;
        display: flex;

        #TSLeftSection{
            width: 40%;
            height: 100%;            
            display: flex;
            align-items: center;
            position: relative;

            #TSanimationContainer{
                width: 100%;
                height: 100%;
                position: absolute;

                #circleAnimation1{
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.9);
                    opacity: 0.1;
                    position: absolute;
                    left: -150px;
                    bottom: -150px;
                    width: 510px;
                    height: 510px;
                    -webkit-animation: circleAnimation 7s linear infinite;
                    animation: circleAnimation 7s linear infinite;
                }

                #circleAnimation2{
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.9);
                    opacity: 0.08;
                    position: absolute;
                    left: 258px;
                    bottom: -200px;
                    width: 330px;
                    height: 330px;
                    -webkit-animation: circleAnimation 5s linear infinite;
                    animation: circleAnimation 5s linear infinite;
                }

                @keyframes circleAnimation {
                    0%, 100% {
                      border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
                      -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
                      transform: translate3d(0, 0, 0) rotateZ(0.01deg);
                    }
                    34% {
                      border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
                      -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
                      transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
                    }
                    50% {
                      -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
                      transform: translate3d(0, 0, 0) rotateZ(0.01deg);
                    }
                    67% {
                      border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
                      -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
                      transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
                    }
                }

            }

            #TSLSText{
                width: 100%;
                position: relative;

                h1{
                    color: white;
                    font-size: 4rem;
                    font-weight: 900;
                    letter-spacing: 4px;
                    font-family: 'Commissioner', sans-serif;
                }

                img{
                    right: 0px;
                    bottom: 30%;
                    position: absolute;
                }
            }

        }

        #TSRightSection{
            width: 60%;
            height: 100%;
            display: flex;
            justify-content: center;

            #TcarouselContainer{
                width: 100%;
                height: 100%;
                display: flex;
                overflow: hidden;
                align-items: center;
            
                #TcarouselWrapper{
                    width: 100%;
                    height: 100%;
                }
            }

            #testimonialContainer{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                padding: 25px 0px;
                align-content: space-between;

                #TCcontent{
                    width: 100%;
                    // height: 50%;
                    padding: 15px;
                    color: white;
                    overflow-y: auto;
                    p{
                        font-size: 2rem;
                    }
                }

                #TCcontent::-webkit-scrollbar {
                    width: 5px;
                    background-color: var(--primary-color);
                }

                #TCcontent::-webkit-scrollbar-thumb{
                    background-color: white;
                    border-radius: 50px;
                }

                #TCInfo{
                    width: 100%;
                    display: flex;
                    align-content: space-between;

                    #TCImageSection{
                        padding: 13px 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img{
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                        }

                    }

                    #TCNameSection{
                        width: 70%;
                        height: 100%;
                        padding: 13px;
                        color: white;
                        .testyName{
                            font-size: 1.8rem;
                            font-weight: 700;
                        }
                        .testyDesig{
                            font-size: 1.4rem;
                        }
                    }
                }

            }

        }

    }

}


@media screen and (max-width: 600px){
    #testimonialSectionContainer{
        flex-direction: column;
        #TSLeftSection{
            height: 15% !important;
            width: 100% !important;

            #TSanimationContainer{
                display: none !important;
            }

            #TSLSText{
                position: relative;
    
                h1{
                    text-align: center;
                    margin: 20px 0px;
                    width: 100% !important;
                    font-size: 1.5rem !important;
                    font-weight: 500;
                    letter-spacing: 0px !important;
                }
            }
        }
    }
    #TCImageSection{
        img{
            width: 70px !important;
            height: 70px !important;
        }
    }

    #TSRightSection{
        width: 100% !important;
        height: 85% !important;
    }
    
    #TCcontent{
        p{
            font-size: 1.2rem !important;
        }
    }

    .testyName{
        font-size: 1.4rem !important;
        font-weight: 700;
    }
    .testyDesig{
        font-size: 1rem !important;
    }

}