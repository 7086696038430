#commentContainer{
    width: 100%;
    height: 80%;
    margin: 15px 0px;
    // border: 1px solid black;
}

#comment{
    width: 100%;
    // height: 50%;
    display: flex;
    align-items: center;
    // justify-content: center;
    // border: 1px solid black;

    #commentLeftSection{
        width: 50px;
        height: 100%;
        border-left: 1px solid black;
    }

    
    #commentRightSection{
        width: 90%;
        height: 100%;
        padding: 5px 15px;
        
        #authorNameContainer{
            width: 100%;
            height: 25%;
            display: flex;
            // border: 1px solid black;
            
            h1{
                margin: 0px 25px;
            }

            #avatarSection{
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 50%;
                border: 1px solid black;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }
        }

        #commentContent{
            width: 100%;
            height: 20%;
            
            #likeShareReply{
                height: 30px;
                display: flex;
                align-items: center;

                i{
                    margin: 0px 5px;
                }

                p{
                    margin: 0px 20px;
                    cursor: pointer;
                }

                #replyButton{
                    cursor: pointer;
                }
            }
            #likeShareReply i:nth-child(2){
                transform: rotateY(180deg);
            }
        }

    }

}

#commentContainer:nth-child(1){
    border: none;
}

// #parentContainer{
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
// }

// #childContainer{
//     width: 10%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     background-color: aqua;
//     border: 1px solid black;
// }