
#footerContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: var(--primary-color);
    background-color: black;

    #footerWrapper{
        width: 85%;
        display: flex;
        flex-wrap: wrap;
        margin: 25px 0px;
        align-content: space-between;
        position: relative;

        #footerTopSection{
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: center;
            
            #topLeftSection{
                width: 40%;
                height: 100%;
                img{
                    width: 100%;
                    height: 90px;
                    margin: 16px;
                    object-fit: contain;
                    float: left;
                }
                p{
                    text-align: justify;
                    color: white;
                }
            }
            #topRightSection{
                width: 60%;
                display: flex;
                flex-wrap: wrap;
                align-content: center;

                #socialMediaContainer{
                    width: 100%;
                    height: 80%;
                    padding: 25px 0px;
                    display: flex;
                    color: white;
                    justify-content: center;
                    align-items: center;
                    p{
                        font-weight: bold;
                        display: flex;
                        font-size: 1.6rem;
                        align-items: center;
                        justify-content: center;
                        a{
                            color: white;
                            i{
                                margin: 0px 10px;
                                font-size: 2.5rem;
                            }
                        }
                    }
                }
            }
        }

        #footerBottomSection{
            width: 100%;
            height: 15%;
            display: flex;
            margin: 25px 0px;
            align-items: center;
            position: relative;
            
            
        }

        #footerBottomSection::after {
            content: '';
            display: block;
            width: 80%;
            position: absolute;
            top: 0px;
            left: 50%;
            margin-left: -40%;
            height: 1px;
            // border: 1px solid black;
            background: radial-gradient(ellipse at center, rgba(222, 228, 239, 0.8) 0, rgba(255, 255, 255, 0) 75%);
        }
    }
}


@media screen and (max-width: 900px) {
    #footerTopSection{
        flex-direction: column;
        #topLeftSection, #topRightSection{
            width: 100% !important;
        }

    }

}

@media screen and (max-width: 600px) {
    #footerWrapper{
        width: 100% !important;
    }
    #topLeftSection{
        width: 40%;
        height: 100%;
        img{
            width: 100% !important;
            margin: 0px !important;
        }
    }

    #socialMediaContainer{
        p{
            font-size: 1rem !important;
            a{
                i{
                    margin: 0px 10px;
                    font-size: 1.5rem !important;
                }
            }
        }
    }
    #followUs{
        margin: 0px !important;
        white-space: nowrap;
    }
}

@media screen and (max-width: 400px) {
    #socialMediaContainer{
        flex-direction: column;
        p{
            width: 100%;
            font-size: 0.6rem !important;
            a{
                i{
                    margin: 0px 10px;
                    font-size: 1.5rem !important;
                }
            }
        }
    }
}
