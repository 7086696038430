.essentialsDetailsContainer {
    padding-top: 5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    
    .upperSection {
        padding: 3em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .detailsLeft {
            width: 35vw;
            padding: 20px;
            min-width: 325px;
            img {
                width: 100%;
            }

            h2 {
                text-align: left;
            }

               
            button{
                cursor: pointer;
                height: 100%;
                border: none;
                color: white;
                font-size: 1rem;
                font-weight: 600;
                padding: 8px 20px;
                border-radius: 4px;
                background: #C8102E;
            }
        }

        .detailsRight {
            width: 35vw;
            min-width: 325px;

            .userComment {
                p, textarea, input {
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    padding: 10px;
                    
                    background-color: #f7f7f7;
                    border-radius: 4px;
                    border: 1px solid rgba(0,0,0,0.1);
                    
                    font-size: clamp(.5rem, 2vw + 0.75rem, 1rem);
                }
                
                textarea {
                    min-height: 130px;
                }

                button {
                    padding: 4px 24px;
                    background: #232323;
                    border-color: #232323;
                    color: #fff;
                    font-size: clamp(.5rem, 2vw + 0.75rem, 1rem);
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }

    .lowerSection {
        background-color: #1c1c1c;
        width: 100%;
        .essentialContent {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
        }
    }
}