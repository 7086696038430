#teamMembersWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    #teamMemberContainer{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;

        #upperSection{
            width: 100%;
            height: 40vh;
            #design, #heading, #subTitle{
                width: 100%;
            }

            #design{
                display: flex;
                padding: 3px;
                justify-content: center;
                .bar{
                    width: 3px;
                    height: 15px;
                    margin: 0px 3px;
                    border: 1px solid #00b3ff;
                    transform: rotate(15deg);
                    background-color: #00b3ff;
                }
            }

            #heading{
                display: flex;
                justify-content: center;
                font-size: 1.2rem;
                font-weight: 600;
                color: #183a39;
                text-align: center;
            }

            #subTitle{
                display: flex;
                justify-content: center;
                font-size: 1rem;
                font-weight: 600;
                text-align: center;
                color: #6a858c;
            }
        }


        #lowerSection{
            width: 100%;
            display: flex;
            justify-content: center;
            background: linear-gradient(118deg, rgba(32,155,212,1) 0%, rgba(119,47,232,1) 100%);
            
            #lowerSectionWrapper{
                width: 85%;
                display: flex;
                justify-content: space-between;

                .card{
                    top: -25%;
                    width: 23%;
                    min-height: 50vh;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
    
                    .image, .name{
                        width: 100%;
                        height: 50%;
                    }
    
                    .image{
                        display: flex;
                        justify-content: center;
                        img{
                            width: 150px;
                            height: 150px;
                            object-fit: cover;
                            border-radius: 50%;
                            border: 8px solid white;
                        }
                    }
    
                    .name{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        color: #fff;
                        text-align: center;
                        p:nth-child(1){
                            width: 100%;
                            font-size: 1.5rem;
                            font-weight: 600;
                        }
                        p:nth-child(2){
                            width: 100%;
                            font-size: 1rem;
                            font-weight: 600;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 1000px){
    #lowerSectionWrapper{
        width: 100% !important;
    }
}

@media screen and (max-width: 850px){
    #lowerSectionWrapper{
        row-gap: 35px;
        flex-direction: column;
        width: 100% !important;
        
        .card{
            padding: 15px;
            top: 0% !important;
            width: 100% !important;
            min-height: auto !important;
            flex-wrap: nowrap !important;

            .name, .image{
                height: 100% !important;
            }

            .image{
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 500px){

    #upperSection{
        margin: 25px 0px;
        height: auto !important;
    }

    #heading{
        font-size: 0.9rem !important;
    }
    #subTitle{
        padding: 0px 15px;
        font-size: 0.7rem !important;
    }
    
}